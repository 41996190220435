import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";

// We can create custom styled components using JSS
// We define the styles here
const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "grey",
  },
  icon: {
    width: "0.5em",
    paddingLeft: 2,
  },
});

// Then inject them here
// ToDO: Can we do the same for a huge component, say a Table or Form?
const MyUrlField = ({ record = {}, source }) => {
  const classes = useStyles();
  const path = "/#/companies/" + record[source] + "/hubsSubColl";
  return (
    <a href={path} className={classes.link}>
      {record[source]}
      <LaunchIcon className={classes.icon} />
    </a>
  );
};

export default MyUrlField;

import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Datagrid,
  useShowController,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  DateField,
  Filter,
  useRedirect,
  useNotify,
  ReferenceField,
  NumberInput,
  BooleanInput,
  SelectInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  useRefresh,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import { validateRequired, validateAmount } from "../../utils/validators";
import { Typography } from "@material-ui/core";
import { DateTimeInput } from "react-admin-date-inputs2";
import DateUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Styles from "../../styles/Styles";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import EmptyListPage from "../../components/EmptyListPage";
import PaymentIcon from "@material-ui/icons/LocalAtm";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneNumberField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import Fab from "@material-ui/core/Fab";
import { db } from "../../config/firebaseConfig";
import Transaction from "../../components/Payments/PaymentTransaction";
import parseDateTime from "../../utils/parseDateTime";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";

const PaymentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="accountNumber" alwaysOn />
    <TextInput label="Status" source="status" />
  </Filter>
);

export const PaymentList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const paymentsExporter = (payments) =>
    exporter({
      resourceArr: payments,
      companyId: companyId,
      resourceType: "payments",
    });
  // watch the collection and refresh the view if any data changes
  const refresh = useRefresh();
  db.collection("payments").onSnapshot((snap) => {
    console.log("~~~~ payments changed! ~~~");
    refresh();
  });

  return companyId ? (
    <List
      {...props}
      sort={{ field: "date", order: "DESC" }}
      title="Payments"
      filters={<PaymentsFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={PaymentIcon} />}
      exporter={
        formattedPermissions.payments && formattedPermissions.payments.list
          ? paymentsExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <DateField source="date" />
        <TextField source="amount" />
        <TextField source="accountName" />
        <TextField source="accountNumber" />
        <ReferenceField
          label="Consignment"
          source="consignment.id"
          reference="consignments"
          link="show"
        >
          <TextField source="trackingCode" />
        </ReferenceField>
        <TextField source="status" />
        {props.permissions && props.permissions.payments.edit && <EditButton />}
        {props.permissions && props.permissions.payments.delete && (
          <DeleteButton undoable={false} />
        )}
      </Datagrid>
    </List>
  ) : null;
};

const TwShow = (props) => {
  const {
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  const [state, setState] = useState({
    transactionId: "",
    transactionDesc: "",
    transactions: [],
  });

  const { transactionId, transactions } = state;

  useEffect(() => {
    const getPaymentTransaction = async () => {
      const transRef = db.collection(
        `payments/${props.match.params.id}/transactions`
      );
      try {
        const transactions = await transRef.get();
        setState((state) => ({
          ...state,
          transactions: transactions.docs,
        }));
        transactions.forEach((trans) => {
          setState((state) => ({
            ...state,
            transactionId: trans.id,
          }));
        });
      } catch (error) {
        console.error(
          "********** error fetching payment transaction ********",
          error
        );
      }
    };

    getPaymentTransaction();
  }, [props.match.params.id]);

  return (
    <div>
      {record !== undefined && (
        <div style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} style={Styles.showHeader}>
              <Typography variant="h5" style={Styles.showContent} gutterBottom>
                Payment Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" style={{ marginLeft: "1em" }}>
                {" "}
                {defaultTitle}
              </Typography>
            </Grid>

            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ marginLeft: "2em" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Account Name: {record.accountName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Account Number: {record.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Payment Amount: {record.amount}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Payment Mode: {record.mode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Transaction Id: {transactionId}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Status: {record.status}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ marginLeft: "2em" }}
            >
              <h3>Transactions</h3>
              <Transaction transactions={transactions} />
            </Grid>
          </Grid>
          {props.permissions && props.permissions.payments.edit && (
            <Fab
              color="primary"
              style={{
                margin: 0,
                top: "auto",
                right: 20,
                bottom: 20,
                left: "auto",
                position: "fixed",
              }}
              variant="extended"
            >
              <EditButton
                style={{ color: "white" }}
                to={`/payments/${record.id}`}
              />
            </Fab>
          )}
        </div>
      )}
    </div>
  );
};

export const PaymentShow = (props) => (
  <TwShow {...props} title="Payment">
    <SimpleShowLayout></SimpleShowLayout>
  </TwShow>
);

export const PaymentForm = ({ record }) => {
  const profile = useContext(ProfileContext);
  const [paymentOptionHelperText, setPaymentOptionHelperText] = useState("");
  const [requestPaymentHelperText, setRequestPaymentHelperText] = useState("");

  const paymentModeChoices = [
    {
      id: "mpesaCheckout",
      name: "Mpesa Checkout",
      helperText:
        "Mpesa Checkout. Send a prompt to the customer's phone directly. They only need to enter their M-Pesa PIN to confirm the payment - the funds will be credited to your account",
    },
    {
      id: "mpesaPaybill",
      name: "Mpesa Paybill",
      helperText:
        "Mpesa Paybill. This option will send an SMS to the customer with instructions on how to make a payment to our paybill number - the funds will be credited to your account",
    },
    {
      id: "cash",
      name: "Cash/Credit",
      helperText:
        "Cash/Credit. Already received cash or is the customer booking on credit? Just record the payment to keep your accounts up to date",
    },
  ];

  const paymentTypes = [
    { id: "collection", name: "Collection" },
    { id: "disbursement", name: "Disbursement" },
  ];

  const handleOnPaymentOptionChange = (value) => {
    let optionHelperText = paymentModeChoices.find(
      (choice) => choice.id === value
    );
    setPaymentOptionHelperText(optionHelperText.helperText);
  };

  const handleOnRequestPaymentChange = (value) => {
    const helperText =
      "This will send a reminder to the customer for them to complete making the payment using the option you selected above";
    value
      ? setRequestPaymentHelperText(helperText)
      : setRequestPaymentHelperText("");
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          {/*Set this payment type select to read only 
           with disbursement as default payment for two reasons:
             (1) Restrict all create actions to disbursement payment
             (2) Restrict altering payment type during edits
            During edit actions, the saved payment type will be loaded and 
            if it is a collection payment, the collection dependent inputs will be displayed
        */}
          <SelectInput
            source="type"
            choices={paymentTypes}
            validate={validateRequired}
            initialValue={paymentTypes[1].id}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <NumberInput
            source="amount"
            validate={validateAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Ksh</InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === "collection" && (
                <RadioButtonGroupInput
                  source="mode"
                  style={{ height: "7em" }}
                  choices={paymentModeChoices}
                  label="Select the method through which the customer will make the payment"
                  onChange={handleOnPaymentOptionChange}
                  helperText={paymentOptionHelperText}
                  validate={validateRequired}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
        </Grid>
        <Grid item sm={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type === "collection" && (
                <BooleanInput
                  label="Send a payment request"
                  style={{ height: "5em" }}
                  source="requestPayment"
                  initialValue={false}
                  onChange={handleOnRequestPaymentChange}
                  helperText={requestPaymentHelperText}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
        </Grid>
        <Grid item sm={6}>
          <TextInput source="accountName" fullWidth />
        </Grid>
        <Grid item sm={6}>
          <PhoneNumberField
            name="accountNumber"
            defaultValue={record && record.accountNumber}
          />
        </Grid>
        <HiddenTextInput source="hub" initialValue={profile.admin.hub} />
        <HiddenTextInput source="status" initialValue="initiated" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "collection" && (
              <HiddenTextInput
                source="consignment.id"
                validate={validateRequired}
                fullWidth
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "disbursement" && (
              <HiddenTextInput
                source="mode"
                initialValue="mpesaB2C"
                validate={validateRequired}
                fullWidth
              />
            )
          }
        </FormDataConsumer>
      </Grid>
    </div>
  );
};

export const PaymentCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The payment was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The payment was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Payment/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <div style={{ width: "100%" }}>
          <PaymentForm />
        </div>
      </SimpleForm>
    </Create>
  );
};

export const PaymentEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The payment was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The payment was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Payment/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} warnWhenUnsavedChanges>
            <PaymentForm />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { StyledTextField } from "../../../AuthStyles";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const SecondaryView = ({
  email,
  password,
  formErrors,
  onChangeHandler,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  isSigningInWithEmailAndPswd,
  isSigningInWithEmailAndLink,
  EmailAndPswdBtn,
  EmailAndLinkBtn,
  handleBack,
}) => {
  return (
    <Fragment>
      <Button
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        onClick={handleBack}
      >
        Back
      </Button>
      <StyledTextField
        variant="outlined"
        margin="normal"
        label="Email Address"
        name="userEmail"
        value={email}
        placeholder="E.g: faruq123@gmail.com"
        id="userEmail"
        onChange={(event) => onChangeHandler(event)}
        autoComplete="email"
        required
        fullWidth
        autoFocus
        error={formErrors.email}
        helperText={formErrors.email && formErrors.email}
      />
      {/* @todo - put this password functionality into a component to allow reuse */}
      {isSigningInWithEmailAndPswd && (
        <StyledTextField
          variant="outlined"
          margin="normal"
          label="Password"
          name="password"
          value={password}
          id="password"
          onChange={(event) => onChangeHandler(event)}
          autoComplete="password"
          required
          fullWidth
          autoFocus
          error={formErrors.password}
          helperText={formErrors?.password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {isSigningInWithEmailAndPswd && <EmailAndPswdBtn />}
      {isSigningInWithEmailAndLink && <EmailAndLinkBtn />}
    </Fragment>
  );
};

SecondaryView.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  formErrors: PropTypes.object.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  handleClickShowPassword: PropTypes.func.isRequired,
  handleMouseDownPassword: PropTypes.func.isRequired,
  isSigningInWithEmailAndLink: PropTypes.bool.isRequired,
  isSigningInWithEmailAndPswd: PropTypes.bool.isRequired,
  EmailAndLinkBtn: PropTypes.func,
  EmailAndPswdBtn: PropTypes.func,
  handleBack: PropTypes.func.isRequired,
};

export default SecondaryView;

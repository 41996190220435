/* eslint-disable */
import React, { useContext, useState, Fragment, useEffect } from "react";
import { navigate } from "@reach/router";
import {
  auth,
  sendSignInLinkToEmail,
  googleProvider,
} from "../../config/firebaseConfig";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  useStyles,
  StyledTextField,
  SignUpWrapper,
  SignUpHeading,
  HelperText,
  LinkText,
  SectionDividerText,
} from "./AuthStyles";
import IndeterminateLoading from "../../components/IndeterminateLoading";
import {
  AuthLink,
  BtnGroup,
  SignUpPreview,
  SignUpPreviewText,
  AuthPreviewBtn,
} from "./AuthStyles";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import _ from "lodash";
import AuthPreview from "./AuthPreview";

const SignUp = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    phoneNumber: "",
    password: "",
    formErrors: {
      email: null,
      password: null,
    },
    showPassword: false,
    showSubsequentSteps: false,
    completeSignup: false,
    googleAuthUserPreview: {},
  });
  const [notification, setNotification] = useState({
    severity: "error",
    msg: null,
  });

  const [showDialog, setShowDialog] = React.useState(false);

  const {
    firstName,
    lastName,
    displayName,
    email,
    phoneNumber,
    password,
    formErrors,
    showPassword,
    showSubsequentSteps,
    completeSignup,
    googleAuthUserPreview,
  } = state;

  useEffect(() => {
    /**
     * retrieve state sent from the sign-in page if a user
     * attempted to sign-in with an accout that doesn't a company
     */
    if (
      localStorage.getItem("completeSignUp") &&
      localStorage.getItem("googleAuthUserPreview")
    ) {
      setState((state) => ({
        ...state,
        completeSignup: JSON.parse(localStorage.getItem("completeSignUp")),
        googleAuthUserPreview: JSON.parse(
          localStorage.getItem("googleAuthUserPreview")
        ),
      }));
    } else if (localStorage.getItem("completeSignInWithEmail")) {
      setNotification({
        severity: "info",
        msg:
          "We couldn't find an account with the details you provided, Sign Up instead?",
      });
    }
  }, []);

  /**
   * sign up with the google auth provider
   */
  const handleSignUpWithGoogle = async () => {
    try {
      const authResult = await auth.signInWithPopup(googleProvider);
      /**
       * we can get auth and user info from the authResult
       * @example
       * const credential = authResult.credential;
       * const token = credential.accessToken;
       * const user = authResult.user;
       */
      if (authResult.user.phoneNumber) {
        // redirect to '/register-company'
        navigate("/register-company");
      } else {
        setState((state) => ({
          ...state,
          completeSignup: true,
          googleAuthUserPreview: { ...authResult.user },
        }));
      }
    } catch (err) {
      // handle errors
      console.error(
        "**** an error occurred when signing up with google ****",
        err
      );
      setNotification({
        ...notification,
        msg: err.message,
      });
    }
  };

  /**
   * initiates passwordless auth flow on the signup page by requesting firebase
   * to send the auth link to a user's email. save any data from sign up that may
   * be needed in subsequent steps i.e. sign-ins
   * @param {React.ChangeEvent} event the button click submitting signup data
   * @param {string} emailAddr the user's email address
   */
  const createUserWithEmail = async (event, email) => {
    event.preventDefault();
    // add phone number to localStorage -> will be used in companySignup
    localStorage.setItem("adminPhoneNumber", phoneNumber);
    localStorage.setItem("adminDisplayName", displayName);
    sendSignInLinkToEmail(email)
      .then((result) => {
        console.log("sign up with email sent with result: ", result);
        // display loading dialog
        setShowDialog(true);
      })
      .catch((err) => {
        console.log(
          " -------------- error with sending sign in email link: ",
          err
        );
        setNotification({
          ...notification,
          msg: err.message,
        });
        // hide loading dialog
        setShowDialog(false);
      });

    // // clear the form fields
    // setState((state) => ({
    //   firstName: "",
    //   lastName: "",
    //   displayName: "",
    //   email: "",
    // }));
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    // dynamically update form inputs
    setState((state) => ({
      ...state,
      [name]: value,
      displayName: `${firstName} ${lastName}`,
    }));
  };

  const handlePhoneNumberChange = (value) =>
    setState((state) => ({
      ...state,
      phoneNumber: value,
    }));

  const handleEmailAndPasswordSignUp = async (e) => {
    e.preventDefault();
    // add phone number to localStorage -> will be used in companySignup
    localStorage.setItem("adminPhoneNumber", phoneNumber);
    localStorage.setItem("adminDisplayName", displayName);
    const formFieldIsRequiredText = "* This field is required";
    if (!email && !password) {
      setState((state) => ({
        ...state,
        email: formFieldIsRequiredText,
        password: formFieldIsRequiredText,
      }));
    } else if (!email) {
      setState((state) => ({
        ...state,
        email: formFieldIsRequiredText,
      }));
    } else if (!password) {
      setState((state) => ({
        ...state,
        password: formFieldIsRequiredText,
      }));
    } else {
      try {
        const userCreds = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        const createdUser = userCreds.user;

        // update the created user with a display name matching the first + last name
        createdUser
          .updateProfile({
            displayName: `${firstName} ${lastName}`,
          })
          .then(() => {
            console.log(
              "*** success: the created user's displayName has been updated ****"
            );
          })
          .catch((err) =>
            console.error(
              "**** an error occurred while updating the created user's displayName ****",
              err
            )
          );

        // redirect to "/register-company"
        navigate("/register-company");
        console.log(
          "******* success: created user ********",
          createdUser,
          "*** aditional info ****",
          userCreds.additionalUserInfo
        );
      } catch (err) {
        // display the error to the user
        console.log(
          "***** an error occurred while creating user with emai + psdw ******",
          err
        );
        setNotification({
          ...notification,
          msg: err.message,
        });
      }
    }
  };

  const handleClickShowPassword = () =>
    setState((state) => ({
      ...state,
      showPassword: !showPassword,
    }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const toggleAuthSteps = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      showSubsequentSteps: !showSubsequentSteps,
    }));
  };

  const handleCompleteSignup = (e) => {
    e.preventDefault();
    // add phone number to localStorage -> will be used in companySignup
    localStorage.setItem("adminPhoneNumber", phoneNumber);
    localStorage.setItem("adminDisplayName", googleAuthUserPreview.displayName);
    navigate("/register-company");
  };

  const handleCloseNotification = (e) => {
    e.preventDefault();

    // reset the notification object
    setNotification({
      severity: "error",
      msg: null,
    });

    // clear any info that had been set in localStorage from sign-in
    localStorage.removeItem("completeSignUp");
    localStorage.removeItem("googleAuthUserPreview");
    localStorage.removeItem("completeSignInWithEmail");
  };

  return (
    <SignUpWrapper>
      <IndeterminateLoading
        showDialog={showDialog}
        message="Waiting for email verification. Please check the message we sent to your email address"
      />
      <SignUpHeading>Sign Up</SignUpHeading>
      {notification.msg !== null && (
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
        >
          {notification.msg}
        </Alert>
      )}
      <form className="">
        {!completeSignup && !showSubsequentSteps && (
          <Fragment>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.googleBtn}
              onClick={handleSignUpWithGoogle}
            >
              Sign up with Google
            </Button>
            <SectionDividerText align="center">or</SectionDividerText>
            <StyledTextField
              variant="outlined"
              margin="normal"
              label="First Name"
              name="firstName"
              value={firstName}
              placeholder="E.g: John"
              id="firstName"
              onChange={(event) => onChangeHandler(event)}
              autoComplete="first name"
              required
              fullWidth
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              label="Last Name"
              name="lastName"
              value={lastName}
              placeholder="E.g: Doe"
              id="lastName"
              onChange={(event) => onChangeHandler(event)}
              autoComplete="last name"
              required
              fullWidth
            />
            <StyledTextField
              variant="outlined"
              margin="normal"
              label="Email address"
              name="email"
              value={email}
              placeholder="E.g: abcd@example.com"
              id="email"
              onChange={(event) => onChangeHandler(event)}
              autoComplete="email"
              required
              fullWidth
              error={formErrors.email}
              helperText={formErrors?.email}
            />
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={toggleAuthSteps}
            >
              Next
            </Button>
          </Fragment>
        )}
        {completeSignup && !_.isEmpty(googleAuthUserPreview) && (
          <Fragment>
            <AuthPreview
              displayName={googleAuthUserPreview.displayName}
              email={googleAuthUserPreview.email}
            />
            <div style={{ margin: "1rem 1rem", marginTop: "2rem" }}>
              <InputLabel className={classes.label}>Phone Number:</InputLabel>
              <PhoneInput
                defaultCountry="KE"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <Button
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={handleCompleteSignup}
            >
              Next
            </Button>
          </Fragment>
        )}
        {!completeSignup && showSubsequentSteps && (
          <Fragment>
            <AuthPreview
              firstName={firstName}
              lastName={lastName}
              email={email}
              phoneNumber={phoneNumber}
              toggleAuthSteps={toggleAuthSteps}
            />
            <div>
              <InputLabel className={classes.label}>Phone Number:</InputLabel>
              <PhoneInput
                defaultCountry="KE"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <StyledTextField
              variant="outlined"
              margin="normal"
              label="Password"
              name="password"
              value={password}
              id="password"
              onChange={(event) => onChangeHandler(event)}
              required
              fullWidth
              error={formErrors.password}
              helperText={formErrors?.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <BtnGroup>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={handleEmailAndPasswordSignUp}
              >
                Sign Up With Password
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={(event) => {
                  createUserWithEmail(event, email);
                }}
              >
                Send Me A Magic Link
              </Button>
            </BtnGroup>
          </Fragment>
        )}
        <div>
          <HelperText>
            Already have an account?{" "}
            <AuthLink to="/">
              <LinkText>Sign in here</LinkText>
            </AuthLink>{" "}
          </HelperText>
        </div>
      </form>
    </SignUpWrapper>
  );
};
export default SignUp;

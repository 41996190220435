import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    opacity: "1",
    fontWeight: "bold",
    borderRadius: "5px",
    marginBottom: "45px",
  },
});

const SectionHeader = (props) => {
  /* A component for displaying section headers */
  const classes = useStyles();
  let text = props.text || "";

  return (
    <Box display="flex" p={1} bgcolor="#b6b6b6" className={classes.root}>
      <Typography variant="body1" paragraph>
        {text}
      </Typography>
    </Box>
  );
};

export default SectionHeader;

import React, { useContext, useState } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { useMediaQuery } from "@material-ui/core";
import { DashboardMenuItem, MenuItemLink } from "react-admin";
import ResourceName from "../routes/resource-names";

// icons
import FleetIcon from "@material-ui/icons/Commute"; // parcels
import ConsignmentIcon from "@material-ui/icons/CardTravel"; // consignments
import PaymentIcon from "@material-ui/icons/LocalAtm"; // payments
import DispatchIcon from "@material-ui/icons/PresentToAll"; // dispatches
import CustomerIcon from "@material-ui/icons/PeopleOutline"; // customers
import MailOutlineIcon from "@material-ui/icons/MailOutline"; // messages
import CompanyAccessIcon from "@material-ui/icons/Security"; // company & access
import TeamIcon from "@material-ui/icons/AssignmentInd"; // teams
import AdminIcon from "@material-ui/icons/SupervisorAccount"; // admins
import AgentIcon from "@material-ui/icons/DriveEta"; // agent
import useFetchCurrentUserPermissions from "../components/useFetchCurrentUserPermissions";

import SubMenu from "./TwSubMenu";

const Menu = ({ onMenuClick, dense, logout }) => {
  const profile = useContext(ProfileContext);
  const resource_name = new ResourceName(profile.company.id).resourceNameObj;
  const [state, setState] = useState({
    menuParcels: true,
    menuCompany: true,
    menuSettings: false,
  });
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const {
    permissions: {
      payments,
      agents,
      consignments,
      dispatches,
      customers,
      admins,
      messages,
      teams,
    },
  } = useFetchCurrentUserPermissions();

  const desiredPermsArr = ["write", "read"];

  /**
   * checks whether a user has the permsissions required to view a given resource
   * @param {Array} resourcePermsArr - an array of resource permissions, i.e, ["read", "write", "delete"]
   * @returns {Boolean}
   */
  const hasPerms = (resourcePermsArr) =>
    desiredPermsArr.some((item) => resourcePermsArr.includes(item));

  // display only if current user is an admin
  return profile.admin ? (
    <div>
      {" "}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <SubMenu
        handleToggle={() => handleToggle("menuParcels")}
        isOpen={state.menuParcels}
        sidebarIsOpen={open}
        name="Manage Parcels"
        icon={<FleetIcon />}
        dense={dense}
      >
        {consignments && consignments.length && hasPerms(consignments) ? (
          <MenuItemLink
            key={resource_name.consignments}
            to={`/${resource_name.consignments}`}
            primaryText="Consignments"
            leftIcon={<ConsignmentIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {payments && payments.length && hasPerms(payments) ? (
          <MenuItemLink
            to={`/${resource_name.payments}`}
            primaryText="Payments"
            leftIcon={<PaymentIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {dispatches && dispatches.length && hasPerms(dispatches) ? (
          <MenuItemLink
            to={`/${resource_name.dispatches}`}
            primaryText="Dispatches"
            leftIcon={<DispatchIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {customers && customers.length && hasPerms(customers) ? (
          <MenuItemLink
            to={`/${resource_name.customers}`}
            primaryText="Customers"
            leftIcon={<CustomerIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {agents && agents.length && hasPerms(agents) ? (
          <MenuItemLink
            to={`/${resource_name.agents}`}
            primaryText="Agents"
            leftIcon={<AgentIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {messages && messages.length && hasPerms(messages) ? (
          <MenuItemLink
            to={`/${resource_name.messages}`}
            primaryText="Messages"
            leftIcon={<MailOutlineIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuCompany")}
        isOpen={state.menuCompany}
        sidebarIsOpen={open}
        name="Company & Access"
        icon={<CompanyAccessIcon />}
        dense={dense}
      >
        {teams && teams.length && hasPerms(teams) ? (
          <MenuItemLink
            to={`/${resource_name.teams}`}
            primaryText="Teams"
            leftIcon={<TeamIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
        {admins && admins.length && hasPerms(admins) ? (
          <MenuItemLink
            to={`/${resource_name.admins}`}
            primaryText="Admins"
            leftIcon={<AdminIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        ) : null}
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle("menuSettings")}
        isOpen={state.menuSettings}
        sidebarIsOpen={open}
        name="App Settings"
        icon={<SettingsIcon />}
        dense={dense}
      ></SubMenu>
      {isXSmall && logout}
    </div>
  ) : null;
};

export default Menu;

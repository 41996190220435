/* eslint-disable */
import React, { Component, createContext } from "react";
import { auth } from "../config/firebaseConfig";
import AuthAdminProfile from "../pages/auth/authProfile";
import { createOrUpdateUser } from "../pages/auth/CreateUser";

export const ProfileContext = createContext({ profile: null });

class ProfileProvider extends Component {
  // a class to create profile context and pass it to all components of the react-admin

  constructor(props) {
    super(props);
    this.state = {
      // set default value to avoid null errors during compile time when profile properties are is encountered.
      // The the values are updated from Context when user logs in
      profile: {
        company: {
          id: "twende",
        },
        team: {
          permissions: {},
        },
      },
    };
  }

  componentDidMount() {
    let profile;
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          let cachedProfile = localStorage.getItem("profile");
          profile = JSON.parse(cachedProfile);
          if (!profile || profile.newUser) {
            throw "No profile";
          }
          console.log("PROFILE-CACHED: ", profile);
        } catch (error) {
          console.log("ERROR: ", error);
          let userSessionObj = new AuthAdminProfile(authUser);
          await userSessionObj.main();
          profile = userSessionObj.profile;

          // if authenticated user is a new user
          // set a profile property to identify current user as a newUser
          // this will be used to redirect the user to login
          profile.newUser = !profile.company;
          localStorage.setItem("profile", JSON.stringify(profile));
          
          // set trigger to enable offline persistence
          if (profile?.company?.id) {
            localStorage.setItem('setOfflinePersistence', 'true');
          }
        }
        this.setState({ profile });
      } else {
        localStorage.removeItem("profile");
      }
    });
  }

  render() {
    const { profile } = this.state;

    return (
      <ProfileContext.Provider value={profile}>
        {this.props.children}
      </ProfileContext.Provider>
    );
  }
}

export default ProfileProvider;

/* eslint-disable */
import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  DateTimeInput,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";

export const StatusList = (props) => (
  <List {...props} title="Status">
    <Datagrid rowClick="show">
      <TextField source="status" />
      <TextField source="agent" />
      <TextField source="location" />
      <DateField source="updatedOn" />
      <TextField source="comments" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export const StatusShow = (props) => (
  <Show {...props} title="Status">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="agent" />
      <TextField source="location" />
      <DateField source="updatedOn" />
      <TextField source="comments" />
    </SimpleShowLayout>
  </Show>
);

export const StatusCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The status was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The status was not created!", "warning");
    console.log(error);
  };

  const StatusForm = () => (
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="status" />
      <TextInput source="agent" />
      <TextInput source="location" />
      <DateTimeInput source="updatedOn" />
      <TextInput source="comments" />
    </SimpleForm>
  )
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Status/Create"
    >
      <StatusForm />
    </Create>
  );
};

export const StatusEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The status was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The status was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Status/Edit"
    >
      <StatusForm />
    </Edit>
  );
};

import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    // you declare you override default material-ui styles
    MuiDrawer: {
      paper: {
        background: "#00acc1 !important",
        margin: "0 !important",
        paddingTop: "2em !important",
        minHeight: "100vh !important",
        height: "100% !important",
        "& *": { color: "#000" },
        width: "25vw",
      },
    },
  },

  palette: {
    primary: {
      main: "#00acc1",
    },
    secondary: {
      main: "#e55425",
    },
    tertiary: {
      main: "#000039",
    },
  },
});

/* eslint-disable */
import React from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CompanyIcon from '@material-ui/icons/AccountBalanceOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AuthAdminProfile from "./authProfile";
import { db, auth } from "../../config/firebaseConfig";
import IndeterminateLoading from "../../components/IndeterminateLoading";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CompanySignUp = () => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = React.useState(false);

  const [companyName, setCompanyName] = React.useState("");
  const [adminPhoneNumber, setAdminPhoneNumber] = React.useState("");
  const [adminDisplayName, setAdminDisplayName] = React.useState("");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [tagline, setTagline] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // display Loading dialog
    setShowDialog(true);

    var companyId = null;
    var adminOwnerId = null;
    const currentUser = auth.currentUser;

    // set email; then first and last name assuming they appear in that order
    let userName = currentUser.displayName || adminDisplayName; // display name set for email link sign-ins
    let emailAddress = currentUser.email;
    let firstName = userName.split(" ")[0]; // split into array using space and get the first item
    let lastName = userName.substring(firstName.length).trim(); // return string after firstname chars

    const ownerData = {
      firstName,
      lastName,
      emailAddress,
      phoneNumber: adminPhoneNumber,
    };

    const companyData = {
      name: companyName,
      registrationNumber,
      tagline,
    };

    const signinAdmin = async (authUser) => {
      let userSessionObj = new AuthAdminProfile(authUser);
      let signupComplete = false;
      let profile;
      do {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await userSessionObj.main();
        profile = userSessionObj.profile;
        console.log('valid profile: ', profile !== null);
        signupComplete = profile?.team?.permissions;
        console.log("Signup is complete: ", signupComplete);
      } while (!signupComplete)

      localStorage.setItem("profile", JSON.stringify(profile));
      // set trigger to enable offline persistence
      localStorage.setItem('setOfflinePersistence', 'true');
    }

    const checkIfAdminIsRegistered = async () => {
      /**
      * check if an admin already exists with the current user's email
      * This check can also be carried out in the backend but will require an accurate response to be sent back,
      * caught, and an error message/alert with the specific issue shown to the user.
      * For simplicity, I've placed it here for the moment
      */
      console.log('Checking if admin is registered..')
      const adminDocs = await db
        .collectionGroup("admins")
        .where("emailAddress", "==", currentUser.email)
        .get();

      // if admin is already registered with companies, show an alert with the companies details
      if (adminDocs.size > 0) {
        setShowDialog(false); // hide Loading dialog
        const companies = [];
        adminDocs.forEach((doc) => {
          companies.push({id: doc.id, name: doc.data().name})
        })
        alert(`You are already registered with these companies: ${JSON.stringify(companies)}`);
        return true
      }
      return false;
    }

    const checkCompanyIsSet = async (companyId) => {
      try {
        let queryDocSnap = await db
          .collection("companies")
          .doc(companyId)
          .get();
        console.log("doc", queryDocSnap.data());
        let companyData = queryDocSnap.data();
        return "adminsCount" in companyData;
      } catch (error) {
        console.log("Error getting company", error);
        return false;
      }
    };

    const createCompany = async () => {
      // function to create new company using form data
      console.log("in createCompany...");

      try {
        let queryDocSnap = await db.collection("companies").add(companyData);
        console.log("...Company created");

        // check if company has finished setting up
        // if not yet sleep to give time for all company creation to complete
        let companyIsSet = false;
        do {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          companyIsSet = await checkCompanyIsSet(queryDocSnap.id);
          console.log("Company is set: ", companyIsSet);
        } while (!companyIsSet);

        return queryDocSnap.id;
      } catch (error) {
        console.log("Company creation Error", error);
        // hide loading dialog
        setShowDialog(false);
        alert("Signup was not successful. Please try again");
      }
    };

    const checkAdminIsSet = async (companyId, adminId) => {
      try {
        let queryDocSnap = await db
          .doc(`companies/${companyId}/admins/${adminId}`)
          .get();
        console.log("doc", queryDocSnap.data());

        let adminData = queryDocSnap.data();
        console.log("Admin is set. Team ID: ", adminData?.team?.id);
        return adminData?.team !== null;
      } catch (error) {
        console.log("Error getting company", error);
        return false;
      }
    };

    const createAdminOwner = async (companyId) => {
      // function to create owner as admin using auth data and company id
      console.log("in createOwnerAdmin...");

      ownerData.companyId = companyId;
      try {
        let queryDocSnap = await db
          .collection(`companies/${companyId}/admins`)
          .add(ownerData);
        console.log("...OwnerAdmin created");

        // check if admin has finished setting up
        // if not yet sleep to give time for setup to complete
        let adminIsSet = false;
        do {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          adminIsSet = await checkAdminIsSet(companyId, queryDocSnap.id);
        } while (!adminIsSet);

        await currentUser.getIdToken(true); // force refresh tokens to retrieve newly set custom claims
        await signinAdmin(currentUser);
        setShowDialog(false); // hide Loading dialog
        location.assign("/"); // redirect to login
      } catch (error) {
        console.log("Admin creation Error", error);
        // hide loading dialog
        setShowDialog(false);
        alert("Signup was not successful. Please try again");
      }
    };

    // actual creation of company and admin
    // check if the admin is already registered
     let adminIsRegistred = await checkIfAdminIsRegistered()
       if (adminIsRegistred) {
         await auth.signOut(); // signout the user to clear cache
         location.assign('/'); // redirect to login
         return false;    
       }
    companyId = await createCompany();

    if (companyId) {
      await createAdminOwner(companyId);
    } else {
      console.log("No company ID");
      return false;
    }
  };

  React.useEffect(() => {
    const phoneNumber = localStorage.getItem("adminPhoneNumber");
    if (phoneNumber !== null && phoneNumber !== undefined) {
      setAdminPhoneNumber(phoneNumber);
    }

    const displayName = localStorage.getItem("adminDisplayName");
    if (displayName !== null && displayName !== undefined) {
      setAdminDisplayName(displayName);
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <IndeterminateLoading showDialog={showDialog} message="Thank you for registering with Twift. We are setting up your account..."/>
      <CssBaseline />
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <CompanyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Twift Admin
        </Typography>
        <Typography component="h6" variant="h6">
          Please Register your Company
        </Typography>
        <form 
          onSubmit={handleSubmit} 
          className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            label="Company Name"
            name="name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            fullWidth
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Company CAK Registration Number"
            name="registrationNumber"
            type="text"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
            required
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Tagline"
            name="tagline"
            value={tagline}
            type="text"
            onChange={(e) => setTagline(e.target.value)}
            required
            fullWidth
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
          >
            Register
          </Button>   
        </form>
      </div>      
    </Container>
  );
};

export default CompanySignUp;

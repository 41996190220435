import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import NumberOfCustomers from "../../components/dashboardComponents/numberOfCustomers";
import TotalConsignments from "../../components/dashboardComponents/totalConsignments";
import RevenueStatus from "../../components/dashboardComponents/revenueStatus";
import { Line, defaults } from "react-chartjs-2";
import "../../styles/App.css";
import styled from "styled-components";

import { db } from "../../config/firebaseConfig";
import { ProfileContext } from "../../providers/ProfileProvider";

const state = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Revenue",
      fill: false,
      lineTension: 0.5,
      backgroundColor: "#00acc1",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 2,
      data: [65, 59, 80, 81, 56],
    },
  ],
};

const DashboardCards = styled.div`
  margin: 1rem 1rem;
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

defaults.global.maintainAspectRatio = false;

const Dashboard = () => {
  const profile = React.useContext(ProfileContext);
  const [customersNo, setCustomersNo] = React.useState(0);
  const [consignmentsNo, setConsignmentsNo] = React.useState(0);

  React.useEffect(() => {
    const docRef = db.doc(`companies/${profile.company.id}`);
    // fetch the consignment, customer counts summary for this company and
    // listen for any changes in the customer record and reload the UI to
    // reflect the latest changes on the company totals
    const unSubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        console.log("company data has changed, refresh totals cards?. new data => ", doc.data());
        setCustomersNo(doc.data().customersCount);
        setConsignmentsNo(doc.data().consignmentsCount);
      } else {
        console.log(`doc for company ${profile.company.id} doesn't exist!!`)
      }
    });

    return unSubscribe; // same as unSubscribe()?
  }, [profile.company.id]);

  return (
    <div style={{ width: "100%" }}>
      <Card
        style={{
          textAlign: "center",
          marginLeft: "1em",
          marginRight: "1em",
          marginTop: "1em",
        }}
      >
        <CardHeader title="Welcome to Twift" />
        <CardContent>Send Parcels Swiftly with Ease & Peace</CardContent>
      </Card>
      <DashboardCards>
        <TotalConsignments fullWidth consignmentsNo={consignmentsNo}/>
        <NumberOfCustomers fullWidth customersNo={customersNo}/>
        <RevenueStatus fullWidth />
      </DashboardCards>

      <div style={{ marginTop: "2em" }}>
        <article className="canvas-container">
          <Line
            data={state}
            options={{
              title: {
                display: true,
                text: "Average Revenue per month",
                fontSize: 20,
              },
              legend: {
                display: false,
              },
            }}
          />
        </article>
      </div>
    </div>
  );
};

export default Dashboard;

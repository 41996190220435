import React, { useContext } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import { Toolbar, SaveButton } from "react-admin";

// the saveButton is held in a toolbar
const SaveCompanyOnSaveToolbar = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  return companyId ? (
    <Toolbar {...props}>
      <SaveButton
        transform={(data) => ({ ...data, companyId: companyId })}
        submitOnEnter={true}
      />
    </Toolbar>
  ) : null;
};
export default SaveCompanyOnSaveToolbar;

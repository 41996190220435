import { useEffect, useState, useContext } from "react";
import { db } from "../config/firebaseConfig";
import formatPermissions from "./formatPermissions";
import { ProfileContext } from "../providers/ProfileProvider";

const useFetchCurrentUserPermissions = () => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const teamId = profile.team.id;
  const [state, setState] = useState({
    permissions: {},
    formattedPermissions: {},
  });

  const { permissions, formattedPermissions } = state;

  useEffect(() => {
    const fetchCurrentUserTeam = async () => {
      try {
        const ownersTeam = await db
          .collection(`companies/${companyId}/teams`)
          .doc(teamId)
          .get();
        if (ownersTeam.exists) {
          const rawUserPerms = ownersTeam.data().permissions;
          const formattedUserPerms = formatPermissions(rawUserPerms, companyId);
          setState((state) => ({
            ...state,
            permissions: rawUserPerms,
            formattedPermissions: formattedUserPerms,
          }));
        } else {
          console.log(
            "******** err: we couldn't find the team that you were looking for ****************"
          );
        }
      } catch (err) {
        console.error(
          "******** err: an error occurred while feching the current user's team ************",
          err
        );
      }
    };

    teamId && fetchCurrentUserTeam();
  }, [profile, companyId, teamId]);

  /**
   * This will be used to override the default authProvider.getPermission
   * because authProvider expects a function
   */
  const getUserPerms = () => formattedPermissions;

  return { permissions, formattedPermissions, getUserPerms };
};

export default useFetchCurrentUserPermissions;

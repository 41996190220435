import ResourceNames from "../routes/resource-names";

/**
 * formats raw user permissions fetched from team.permissions
 * why? we use ra-admin-acl to manage resource access based on perms and it needs a formatted perms object
 * for more info @see - https://github.com/marmelab/ra-auth-acl
 *
 * @params {Object} - raw user permission of shape {
 *  fleet: ["read", "write", "delete"],
 *  consignments: ["read", "write", "delete"],
 *  ...
 * }
 * @returns {Object} - formatted permissions
 */
const formatPermissions = (currentUserPerms, companyId) => {
  // instantiate a new ResourceNames class
  const newResourceNames = new ResourceNames(companyId);
  const resourceName = newResourceNames.resourceNameObj;
  let formattedPerms = {};

  /**
   * loop through currentUserPerms
   * for (key, val) of currentUserPerms { .... }
   */
  for (const [permsName, permsArray] of Object.entries(currentUserPerms)) {
    formattedPerms[resourceName[permsName]] = {
      enabled: permsArray.includes("read"),
      list: permsArray.includes("read"),
      create: permsArray.includes("write"),
      edit: permsArray.includes("write"),
      show: permsArray.includes("read"),
      delete: permsArray.includes("delete"),
    };
  }

  return formattedPerms;
};

export default formatPermissions;

/* eslint-disable */
import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateField,
  DateInput,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";

export const WayBillList = (props) => (
  <List {...props} title="Waybills">
    <Datagrid>
      <TextField source="consigner" />
      <TextField source="consignee" />
      <TextField source="payment" />
      <DateField source="dateReceived" />
      <TextField source="podMethod" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export const WayBillShow = (props) => (
  <Show {...props} title="Waybill">
    <SimpleShowLayout>
      <TextField source="consigner" />
      <TextField source="consignee" />
      <TextField source="payment" />
      <DateField source="dateReceived" />
      <TextField source="podMethod" />
    </SimpleShowLayout>
  </Show>
);

const WayBillForm = () => (
  <SimpleForm warnWhenUnsavedChanges>
    <TextInput source="consigner" />
    <TextInput source="consignee" />
    <Textinput source="payment" />
    <DateInput source="dateReceived" />
    <TextInput source="podMethod" />
  </SimpleForm>
)

export const WayBillCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The waybill was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The waybill was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Waybill/Create"
    >
      <WayBillForm />
    </Create>
  );
};

export const WayBillEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The waybill was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The waybill was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Waybill/Edit"
    >
      <WayBillForm />
    </Edit>
  );
};

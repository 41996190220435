import React, { useState, useEffect, Fragment } from "react";
import { db } from "../../config/firebaseConfig";
import {
  SimpleShowLayout,
  useShowController,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  useRedirect,
  useNotify,
  SelectInput,
  EditButton,
} from "react-admin";
import SaveAddressOnSaveToolbar from "../../components/SaveAddressOnConsignmentSave";
import CustomerInput from "../../components/customerInput";
import PaymentQuickCreate from "../../components/PaymentQuickCreate";
import { validateRequired } from "../../utils/validators";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import { DateTimeInput } from "react-admin-date-inputs2";
import DateUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import Styles from "../../styles/Styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import styled from "styled-components";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TwTooltip from "../../layout/TwToolTip";
import enums from "../../enums";
import { ProfileContext } from "../../providers/ProfileProvider";
import parseDateTime from "../../utils/parseDateTime";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const { consignmentStatusArray } = enums;

// urgency choices
const urgencyChoices = [
  { id: "3", name: "Very Urgent" },
  { id: "2", name: "Normal" },
  { id: "1", name: "Not Urgent" },
];

const statusChoices = [
  { id: "created", name: "Created" },
  { id: "dispatched", name: "Dispatched" },
  { id: "delivered", name: "Delivered" },
  { id: "collected", name: "Collected" },
];

/**
 * @description
 * dimension units:
 * L, H, W - cm
 * Weight - kg
 * @example
 * how to calculate weight
 * (L * W * H )/ 5000
 * @link http://wap.dhl.com/serv/volweight.html
 *
 * @example
 * a box's dimensions
 * dimensions: {
 *  height: String 10cm,
 *  length: String 10cm,
 *  width: String 10cm,
 *  weight: Number 0.2kg,
 * }
 */
const dimensionsChoices = [
  {
    id: "xs",
    name: "Extra Small",
    dimensions: { width: "30", length: "25", height: "25", weight: 3 },
  },
  {
    id: "sm",
    name: "Small",
    dimensions: { width: "41", length: "30", height: "30", weight: 7 },
  },
  {
    id: "md",
    name: "Medium",
    dimensions: { width: "46", length: "46", height: "41", weight: 17 },
  },
  {
    id: "lg",
    name: "Large",
    dimensions: { width: "46", length: "46", height: "61", weight: 26 },
  },
  {
    id: "xlg",
    name: "Extra Large",
    dimensions: { width: "61", length: "46", height: "61", weight: 34 },
  },
];

const RenderConsignmentSizeOptions = (sizeOption) => {
  // destructure sizeOption
  const {
    record: { name, id },
  } = sizeOption;

  // get option by filtering from dimensionsChoices the choice that matches the selected one.
  const option = dimensionsChoices.filter((choice) => choice.id === id)[0]
    .dimensions;

  // destructure option to get the dimensions
  const { length, width, height } = option;
  // styles
  const SizeOption = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  `;

  const DimensionsText = styled.small`
    color: #707070;
  `;

  return (
    <SizeOption>
      <div>{name}</div>
      <DimensionsText>{`${length} x ${width} x ${height} LxWxH (cm)`}</DimensionsText>
    </SizeOption>
  );
};

const TwShow = (props) => {
  const classes = useStyles();
  const profile = React.useContext(ProfileContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    paymentInfo: {},
    showPaymentModal: false,
  });

  const { paymentInfo, showPaymentModal } = state;

  const handleNext = (diff) => {
    setActiveStep((prevActiveStep) => prevActiveStep + diff);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const closePaymentModal = () => {
    setState((state) => ({
      ...state,
      showPaymentModal: false,
    }));
  };

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  useEffect(() => {
    const getConsPayment = async () => {
      const paymentRef = db
        .collection("payments")
        .where("companyId", "==", profile.company.id);

      // payments - a list of all payments
      const payments = await paymentRef.get();
      if (payments.docs.length) {
        payments.docs.forEach((payment) => {
          if (payment.data().consignment.id === props.match.params.id) {
            setState((state) => ({
              ...state,
              paymentInfo: payment.data(),
            }));
          }
        });
      }
    };

    getConsPayment();
  }, [props.match.params.id, profile.company.id]);

  useEffect(() => {
    const setActiveSteps = () => {
      const consignmentStatus = record.status.value;
      const elementPosition = consignmentStatusArray.findIndex(
        (step) => step.STATUS === consignmentStatus
      );
      handleNext(elementPosition);
    };
    if (record !== undefined) {
      handleReset();
      setActiveSteps();
    }
  }, [record]);

  const StatusHistoryItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const ActionSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    color: white;
  `;

  return (
    <div>
      {record !== undefined && (
        <div style={{ width: "100%" }}>
          {showPaymentModal && (
            <PaymentQuickCreate
              consignmentId={props.match.params.id}
              onDialogClosed={closePaymentModal}
              defaultState={true}
            />
          )}
          {props.permissions && props.permissions.consignments.edit && (
            <ActionSection>
              <Fab
                color="primary"
                variant="extended"
                aria-label="add"
                style={{ color: "inherit" }}
              >
                <EditButton
                  style={{ color: "inherit" }}
                  to={`/consignments/${record.id}`}
                />
              </Fab>
              <Fab
                style={{ color: "inherit" }}
                color="primary"
                variant="extended"
                aria-label="request payment"
                onClick={(e) => {
                  e.preventDefault();
                  setState((state) => ({
                    ...state,
                    showPaymentModal: true,
                  }));
                }}
              >
                Request Payment
              </Fab>
            </ActionSection>
          )}
          <Grid container spacing={2}>
            {/* Customer Info */}
            <Grid item xs={12} sm={12} style={Styles.showHeader}>
              <Typography variant="h5" style={Styles.showContent} gutterBottom>
                Customer Information
              </Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ margin: "auto" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={Styles.contentHeader}>
                  {" "}
                  Sender{" "}
                </Typography>
              </Grid>
              <Grid style={{ marginLeft: "1em" }}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    First Name: {record.sender.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Last Name: {record.sender.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Phone Number: {record.sender.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Address: {record.sender.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ margin: "auto" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={Styles.contentHeader}>
                  {" "}
                  Recipient
                </Typography>
              </Grid>
              <Grid style={{ marginLeft: "1em" }}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    First Name: {record.recipient.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Last Name: {record.recipient.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Phone Number: {record.recipient.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Address: {record.recipient.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Package Information */}
            <Grid item xs={12} sm={12} style={Styles.showHeader}>
              <Typography variant="h5" style={Styles.showContent} gutterBottom>
                Package Information
              </Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ marginTop: "0.5em" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={Styles.contentHeader}>
                  {" "}
                  Package
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Size:{" "}
                  {record.size !== undefined
                    ? `${record.size.length} by ${record.size.width} by ${record.size.height}`
                    : `N/A`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Item Value: {record.itemValue}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Tracking Code: {record.trackingCode}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Status: {record.status.value}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body1">
                  {" "}
                  Description: {record.description}{" "}
                </Typography>
              </Grid>
            </Grid>

            {/* Status History */}
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={6}
              style={{ margin: "auto" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" style={Styles.contentHeader}>
                  {" "}
                  Status History
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.root}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {consignmentStatusArray.map((status) => (
                      <Step key={status.STATUS}>
                        <StepLabel>
                          <StatusHistoryItem>
                            <Fragment>{status.STATUS}</Fragment>
                            <TwTooltip
                              title={status.MSG}
                              icon={<InfoOutlinedIcon />}
                            />
                          </StatusHistoryItem>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </Grid>
            </Grid>

            {/* Payment Information */}
            <Grid item xs={12} sm={12} style={Styles.showHeader}>
              <Typography variant="h5" style={Styles.showContent} gutterBottom>
                Payment Information
              </Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              item
              xs={12}
              sm={12}
              style={{ margin: "auto" }}
            >
              <Grid style={{ marginLeft: "1em" }}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Account Name: {paymentInfo.accountName}
                  </Typography>
                </Grid>
                {paymentInfo.mode !== undefined && paymentInfo.mode !== "cash" && (
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1">
                      {" "}
                      Account Number: {paymentInfo.accountNumber}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Status: {paymentInfo.status}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1">
                    {" "}
                    Amount: {paymentInfo.amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export const ConsignmentShow = (props) => {
  return (
    <TwShow {...props} title="Consignment">
      <SimpleShowLayout></SimpleShowLayout>
    </TwShow>
  );
};

const ConsignmentForm = (props) => (
  <div style={{ width: "100%" }}>
    <Grid container spacing={2}>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        sm={6}
        style={{ marginRight: "1em" }}
      >
        <Grid item xs={12} sm={12} style={Styles.header}>
          <Typography variant="h6" gutterBottom>
            Ship From
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomerInput
            showWarningWhenUnsavedChanges={props.showWarningWhenUnsavedChanges}
            customerType="sender"
            postSave={props.postSave}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12} sm={12} style={Styles.header}>
          <Typography variant="h6" gutterBottom>
            Deliver to{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomerInput
            showWarningWhenUnsavedChanges={props.showWarningWhenUnsavedChanges}
            customerType="recipient"
            postSave={props.postSave}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} style={Styles.header}>
        <Typography variant="h6" gutterBottom>
          Package Information
        </Typography>
      </Grid>

      <Grid container spacing={2} item xs={12} md={6}>
        <Grid item xs={12} sm={12}>
          <SelectInput
            source="size"
            label="Size"
            variant="outlined"
            optionValue="name"
            optionText={<RenderConsignmentSizeOptions />}
            choices={dimensionsChoices}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectInput
            source="status.value"
            label="Status"
            variant="outlined"
            choices={statusChoices}
            validate={validateRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title="An estimated worth of the item" arrow>
            <NumberInput
              source="itemValue"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Ksh</InputAdornment>
                ),
              }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput source="numberOfPackages" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput source="urgency" choices={urgencyChoices} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput source="description" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiPickersUtilsProvider utils={DateUtils}>
            <DateTimeInput
              source="date"
              label="Shipping Date"
              validate={validateRequired}
              parse={parseDateTime}
              options={{
                format: "DD/MM/YYYY, HH:mm:ss",
                ampm: false,
                clearable: true,
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BooleanInput source="fragile" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HiddenTextInput source="id" />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export const ConsignmentCreate = (props) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [consignmentId, setConsignmentId] = useState("");
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = ({ data }) => {
    notify("The consignment was created successfully");
    setConsignmentId(data.id);
    setShowPaymentDialog(true);
  };

  const onDialogClosed = () => {
    setShowPaymentDialog(false);
    redirect("list", props.basePath);
  };

  const onFailure = () => {
    notify("The consignment was not created!", "warning");
  };

  const showWarningWhenUnsavedChanges = () => {
    return true;
  };

  return (
    <>
      {showPaymentDialog && (
        <PaymentQuickCreate
          consignmentId={consignmentId}
          onDialogClosed={onDialogClosed}
          defaultState={true}
        />
      )}
      <Create
        {...props}
        onSuccess={onSuccess}
        undoable={false}
        onFailure={onFailure}
        title="Consignment/Create"
      >
        <SimpleForm
          toolbar={<SaveAddressOnSaveToolbar />}
          warnWhenUnsavedChanges={false}
        >
          <ConsignmentForm
            showWarningWhenUnsavedChanges={showWarningWhenUnsavedChanges}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export const ConsignmentEdit = (props) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The consignment was updated successfully");
    redirect("list", props.basePath);
  };

  const onDialogClosed = () => {
    setShowPaymentDialog(false);
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The consignment was not updated!", "warning");
    console.log(error);
  };

  const showWarningWhenUnsavedChanges = () => {
    return true;
  };

  return (
    <>
      {showPaymentDialog && (
        <PaymentQuickCreate
          consignmentId={props.match.params.id}
          onDialogClosed={onDialogClosed}
          defaultState={true}
        />
      )}
      <Edit
        {...props}
        onSuccess={onSuccess}
        undoable={false}
        onFailure={onFailure}
        title="Consignment/Edit"
      >
        <SimpleForm
          toolbar={<SaveAddressOnSaveToolbar />}
          warnWhenUnsavedChanges={false}
        >
          <ConsignmentForm
            showWarningWhenUnsavedChanges={showWarningWhenUnsavedChanges}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

import React, { useContext } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Edit,
  Create,
  Datagrid,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  SelectInput,
  TextField,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmptyListPage from "../../components/EmptyListPage";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";

export const MessageList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const msgsExporter = (msgs) =>
    exporter({
      resourceArr: msgs,
      companyId: companyId,
      resourceType: "messages",
    });

  return companyId ? (
    <List
      {...props}
      title="Messages"
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={MailOutlineIcon} />}
      exporter={
        formattedPermissions.messages && formattedPermissions.messages.list
          ? msgsExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <TextField source="title" />
        <TextField source="body" />
        <TextField source="sender" />
        <TextField source="recipient" />
        <TextField source="type" />
        <TextField source="status" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  ) : null;
};

export const MessageShow = (props) => (
  <Show {...props} title="Message">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="body" />
      <TextField source="sender" />
      <TextField source="recipient" />
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="statusDesc" />
    </SimpleShowLayout>
  </Show>
);

const MessageForm = () => (
  <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
    <TextInput source="title" />
    <TextInput multiline source="body" />
    <TextInput source="recipient" label="recipient's Phone Number" placeholder="e.g, +254711000111" />
    <SelectInput
      source="type"
      choices={[
        { id: 'SMS', name: 'SMS' },
        // { id: 'PN, name: 'Push Notification' },
        // { id: 'Chat', name: 'In-App Chat' },
      ]}/>
  </SimpleForm>
)

export const MessageCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The message was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The message was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Message/Create"
    >
      <MessageForm />
    </Create>
  );
};

export const MessageEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The message was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The message was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Message/Edit"
    >
    <MessageForm />
    </Edit>
  );
};

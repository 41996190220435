import React from "react";
import { LogoutBtn } from "./AuthStyles";
import { auth } from "../../config/firebaseConfig";
import { navigate } from "@reach/router";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const SignOut = () => {
  const handleLogout = async (e) => {
    e.preventDefault();
    // clear localStorage
    localStorage.clear();

    try {
      // signout from firebase
      await auth.signOut();

      // redirect to the signin page
      navigate("signin");
    } catch (err) {
      console.error("**** an error occurred while signing out *****", err);

      //@todo - notify users of the failure
    }
  };

  return (
    <LogoutBtn onClick={handleLogout} startIcon={<ExitToAppIcon />}>
      Logout
    </LogoutBtn>
  );
};

export default SignOut;

/* eslint-disable */
import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  NumberField,
} from "react-admin";

export const RatingList = (props) => (
  <List {...props} title="Ratings">
    <Datagrid>
      <TextField source="rater" />
      <TextField source="ratee" />
      <NumberField source="ratingValue" />
      <TextField source="reviewText" />
    </Datagrid>
  </List>
);

export const RatingShow = (props) => (
  <Show {...props} title="Rating">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="rater" />
      <TextField source="ratee" />
      <NumberField source="ratingValue" />
      <TextField source="reviewText" />
    </SimpleShowLayout>
  </Show>
);

// based off of https://www.npmjs.com/package/react-phone-number-input?activeTab=readme
import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import MuIPhoneNumberInput from "./MUIPhoneInput";

const TwPhoneNumberInput = ({ name, placeholder, defaultValue }) => {

  const phoneNumber = defaultValue ? defaultValue : "";

  /**
   * create functions to validate a phone number
   * from react-final-form docs @link https://final-form.org/docs/react-final-form/examples/field-level-validation
   */
  const required = (value) => (value ? undefined : "Required");
  const minLength = (value) => {
    /***
     * Str.replace(/ /g, "") - removes whitespace from a string
     */
    const valueLen = value !== undefined && value.replace(/ /g, "").length;
    console.log(":::::::::::: valueLen ::::::::", valueLen);
    if (valueLen < 12) {
      return "Phone Number must be greater than 13 in length";
    } else {
      return undefined;
    }
  };

  // combine validators
  const composeValidators = (...validators) => (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

  return (
    <Field
      name={name}
      component="select"
      placeholder={placeholder}
      validate={composeValidators(required, minLength)}
    >
      {(props) => {
        return (
          <>
            <PhoneInput
              {...props.input}
              inputComponent={MuIPhoneNumberInput}
              value={phoneNumber}
              placeholder={placeholder}
              defaultCountry="KE"
            />
            {props.meta.error && props.meta.touched && (
              <small
                style={{
                  color: "red",
                  marginTop: "0.1rem",
                  marginLeft: "0.3rem",
                }}
              >
                * {props.meta.error}
              </small>
            )}
          </>
        );
      }}
    </Field>
  );
};

TwPhoneNumberInput.defaultProps = {
  name: "phoneNumber", // matches resource.phoneNumber.
  placeholder: "Enter a phone number",
};

TwPhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export default TwPhoneNumberInput;

import React, { useContext } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  NumberField,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";

export const BookingList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  return companyId ? (
    <List {...props} title="Bookings" filter={{ companyId: companyId }}>
      <Datagrid>
        <TextField source="passenger" />
        <NumberField source="amount" />
        <TextField source="destination" />
        <TextField source="seat" />
        <TextField source="status" />
        <TextField source="hub" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  ) : null;
};

export const BookingShow = (props) => (
  <Show {...props} title="Booking">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="passenger" />
      <NumberField source="amount" />
      <TextField source="destination" />
      <TextField source="seat" />
      <TextField source="status" />
      <TextField source="hub" />
    </SimpleShowLayout>
  </Show>
);

const BookingForm = () => (
  <>
    <TextInput source="passenger" />
    <NumberInput source="amount" />
    <TextInput source="destination" />
    <TextInput source="seat" />
    <TextInput source="status" />
    <TextInput source="hub" />
  </>
)

export const BookingCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The booking was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The booking was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Booking/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <BookingForm />
      </SimpleForm>
    </Create>
  );
};

export const BookingEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The booking was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The booking was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Booking/Edit"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <BookingForm />
      </SimpleForm>
    </Edit>
  );
};

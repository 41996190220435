import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { CountrySelect } from "./styles";

const TwCountryDropdownField = ({ name, placeholder }) => {

  const country = "";
  return (
    <Field name={name} component="select" placeholder={placeholder}>
      {(props) => {
        return (
          <CountrySelect
            style={{ maxWidth: "100%" }}
            value={country}
            {...props.input}
          />
        );
      }}
    </Field>
  );
};

TwCountryDropdownField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default TwCountryDropdownField;

const Styles = {
  input: { marginTop: "1em" },
  header: {
    backgroundColor: "#b6b6b6",
    borderRadius: "10px",
    marginTop: "1em",
    marginBottom: "1em",
    marginRight: "1em",
    marginLeft: "0.5em",
  },
  searchbar: { marginTop: "1em" },
  showHeader: {
    backgroundColor: "#b6b6b6",
    borderRadius: "0.5em",
    marginTop: "2em",
    height: "50px",
    marginLeft: "0.5em",
    marginRight: "1em",
  },
  showContent: { marginLeft: "1em" },
  contentHeader: { fontWeight: "bold" },
};

export default Styles;

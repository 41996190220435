import React from "react";
import { TextInput, ArrayInput } from "react-admin";

/*
	A TextInput component that does not display in a form but can hold and submit data
	Can be used for submitting autofilled/pre-filled fields that don't need to be viewed by the user
*/
const HiddenTextInput = (props) => (
  <TextInput style={{ display: "none" }} {...props} />
);

/*
    An ArrayInput component that does not display in a form but can hold and submit data
    Can be used for submitting autofilled/pre-filled fields that don't need to be viewed by the user
*/
const HiddenArrayInput = (props) => (
  <ArrayInput style={{ display: "none" }} {...props} />
);

export { HiddenTextInput, HiddenArrayInput };

import React, { useState, useContext, useEffect } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import {
  NumberInput,
  Button,
  BooleanInput,
  SaveButton,
  RadioButtonGroupInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  Loading,
  useMutation,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import ReadOnlyTextInput from "./ReadOnlyTextInput";
import { validateRequired, validateAmount } from "../utils/validators";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneNumberField from "../layout/TwPhoneNumberInput";
import { db } from "../config/firebaseConfig";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimeInput } from "react-admin-date-inputs2";
import DateUtils from "@date-io/moment";
import parseDateTime from "../utils/parseDateTime";

const PaymentQuickCreate = (props) => {
  /*
        This component allows one to quickly create payment after creating a consignment
        It's rendered as a Dialog, depending on the showDialog: bool value
    */
  const profile = useContext(ProfileContext);
  const [showDialog, setShowDialog] = useState(props.defaultState); // is null(false) if prop is not set
  const [create, { loading }] = useCreate("payments");
  const [approve] = useMutation();
  const [paymentOptionHelperText, setPaymentOptionHelperText] = useState("");
  const [requestPaymentHelperText, setRequestPaymentHelperText] = useState("");
  const notify = useNotify();

  const [state, setState] = useState({
    initialValues: {},
    consignment: props.consignmentId,
  });

  const { initialValues, consignment } = state;

  useEffect(() => {
    const getPayment = async (currentConsignmentId) => {
      const paymentRef = db
        .collection("payments")
        .where("consignment.id", "==", currentConsignmentId);
      try {
        const querySnapShot = await paymentRef.get();
        if (querySnapShot.docs.length) {
          console.log(
            "******* we have found a payment corresponding to the current cons *********",
            querySnapShot.docs[0].data()
          );
          const existingPayment = {
            ...querySnapShot.docs[0].data(),
            id: querySnapShot.docs[0].id,
          };
          setState((state) => ({
            ...state,
            initialValues: existingPayment,
          }));
        } else {
          // the current consignment doesn't have a payment attached to it

          // thus, let's fetch cons.sender.accountName & cons.sender.accountNumber to prefill
          // a/c name & number which are required fields in this modal
          const consRef = db
            .collection("consignments")
            .doc(currentConsignmentId);
          const cons = await consRef.get();
          if (cons.exists) {
            console.log(
              "+++++++++++++ cons related to this Payment found ++++++++++++++++"
            );
            const paymentInfo = {
              accountName:
                cons.data().sender.firstName +
                " " +
                cons.data().sender.lastName,
              accountNumber: cons.data().sender.phoneNumber,
              // we have to set the status, type and consignment fields otherwise we'll get
              // a PERMISSION_DENIED:false for 'create' @ L165, Property <property> is undefined on object. for 'create' @ L382
              type: "collection",
              status: "initiated",
              consignment: {
                id: props.consignmentId,
                trackingCode: cons.data().trackingCode,
              },
              companyId: profile.company.id,
            };
            console.log(
              "+++++++++++ cons payment info ++++++++++++++++",
              paymentInfo
            );
            setState((state) => ({
              ...state,
              initialValues: paymentInfo,
            }));
          } else {
            console.warn("****** no such cons exists **********");
          }
        }
      } catch (err) {
        console.error("***************************************", err);
      }
    };

    getPayment(consignment);
  }, [consignment, profile.company.id, props.consignmentId]);

  const paymentModeChoices = [
    {
      id: "mpesaCheckout",
      name: "Mpesa Checkout",
      helperText:
        "Mpesa Checkout. Send a prompt to the customer's phone directly. They only need to enter their M-Pesa PIN to confirm the payment - the funds will be credited to your account",
    },
    {
      id: "mpesaPaybill",
      name: "Mpesa Paybill",
      helperText:
        "Mpesa Paybill. This option will send an SMS to the customer with instructions on how to make a payment to our paybill number - the funds will be credited to your account",
    },
    {
      id: "cash",
      name: "Cash/Credit",
      helperText:
        "Cash/Credit. Already received cash or is the customer booking on credit? Just record the payment to keep your accounts up to date",
    },
  ];

  // if payment status is either successful or pending(in process), disable the ability to request for payment
  const paymentMadeStatus = ["success", "pending"];
  const paymentHasBeenRequested = paymentMadeStatus.includes(
    initialValues.status
  );

  const handleOnPaymentOptionChange = (value) => {
    let optionHelperText = paymentModeChoices.find(
      (choice) => choice.id === value
    );
    setPaymentOptionHelperText(optionHelperText.helperText);
  };

  const handleOnRequestPaymentChange = (value) => {
    const helperText =
      "This will send a reminder to the customer for them to complete making the payment using the option you selected above";
    value
      ? setRequestPaymentHelperText(helperText)
      : setRequestPaymentHelperText("");
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    try {
      // if function was passed as prop call it
      props.onDialogClosed();
    } catch (error) {
      // if it wasn't, hence an exception, do nothing
    }
  };

  const handleSubmit = async (values) => {
    console.log("VALUES: ", values);

    values.requestPayment = values.requestPayment ? true : false;

    let notifyMsg;
    switch (values.mode) {
      case "mpesaCheckout":
      case "mpesaPaybill":
        notifyMsg = values.requestPayment
          ? `Payment request has been sent to ${values.accountName} successfully!`
          : "Payment record saved successfully!";
        break;
      default:
        notifyMsg = "Payment record saved successfully!";
        break;
    }
    // merge initialValues with the new values with the former being overwritten field has changed
    const updateData = { ...initialValues, ...values };

    if (initialValues.id) {
      // payment already exists and is being updated
      try {
        approve({
          type: "update",
          resource: "payments",
          payload: { id: initialValues.id, data: updateData },
        });
        notify(notifyMsg, "success");
        handleCloseClick();
      } catch (error) {
        notify(error, "error");
      }
    } else {
      // it's a new payment being created
      create(
        { payload: { data: values } },
        {
          onSuccess: ({ data }) => {
            notify(notifyMsg, "success");
            handleCloseClick();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };
  return (
    <>
      {/* The create payment Dialog */}
      <Button
        onClick={handleClick}
        label="Request Payment"
        disabled={paymentHasBeenRequested}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Consignment Payment"
      >
        <DialogTitle>Consignment Payment Info</DialogTitle>

        <FormWithRedirect
          resource="payments"
          save={handleSubmit}
          initialValues={initialValues}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    <MuiPickersUtilsProvider utils={DateUtils}>
                      <DateTimeInput
                        source="date"
                        validate={validateRequired}
                        label="Payment Date"
                        parse={parseDateTime}
                        options={{
                          format: "DD/MM/YYYY, HH:mm:ss",
                          ampm: false,
                          clearable: true,
                        }}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                    {/* <DateTimeInput source="date" validate={validateRequired} /> */}
                  </Grid>
                  <Grid item sm={6}>
                    <NumberInput
                      source="amount"
                      validate={validateAmount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Ksh</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <RadioButtonGroupInput
                      source="mode"
                      style={{ maxHeight: "25rem" }}
                      choices={paymentModeChoices}
                      label="Select the method through which the customer will make the payment"
                      onChange={handleOnPaymentOptionChange}
                      helperText={paymentOptionHelperText}
                      validate={validateRequired}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <BooleanInput
                      label="Send a payment request"
                      style={{ maxHeight: "25em" }}
                      source="requestPayment"
                      initialValue={false}
                      onChange={handleOnRequestPaymentChange}
                      helperText={requestPaymentHelperText}
                      disabled={paymentHasBeenRequested}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <ReadOnlyTextInput
                      source="accountName"
                      initialValue={initialValues.accountName || ""}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    {initialValues.accountNumber !== undefined ? (
                      <PhoneNumberField
                        name="accountNumber"
                        defaultValue={initialValues.accountNumber}
                        placeholder="Account Number"
                      />
                    ) : (
                      <Loading />
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default PaymentQuickCreate;

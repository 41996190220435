import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  EmailField,
  TextInput,
  EditButton,
  Filter,
  DeleteButton,
  useRedirect,
  useNotify,
  ReferenceManyField,
  ReferenceField,
  DateField,
} from "react-admin";
import { validateName } from "../../utils/validators";
import { Typography } from "@material-ui/core";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import Grid from "@material-ui/core/Grid";
import EmptyListPage from "../../components/EmptyListPage";
import CustomerIcon from "@material-ui/icons/PeopleOutline";
import CountryDropdownField from "../../layout/TwCountryDropdownField";
import PhoneField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import UserCard from "../../layout/UserCard";

const CustomersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="phoneNumber" alwaysOn />
    <TextInput label="Email Address" source="emailAddress" />
  </Filter>
);

export const CustomerList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const customersExporter = (customers) =>
    exporter({
      resourceArr: customers,
      companyId: companyId,
      resourceType: "customers",
    });

  return (
    <List
      {...props}
      title="Customers"
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<CustomersFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={CustomerIcon} />}
      exporter={
        formattedPermissions.customers && formattedPermissions.customers.list
          ? customersExporter
          : false
      }
    >
      <UserCard />
    </List>
  );
};

export const CustomerShow = (props) => (
  <Show {...props} title="Customer">
    <SimpleShowLayout>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phoneNumber" />
      <EmailField source="emailAddress" />
      <TextField source="bio" />
      {props.permissions && props.permissions.customers.edit && <EditButton />}
      {props.permissions && props.permissions.customers.delete && (
        <DeleteButton undoable={false} />
      )}
      <ReferenceManyField
        label="Sent Consignments"
        reference="consignments"
        target="sender.id"
      >
        <Datagrid>
          <DateField source="date" />
          <ReferenceField
            label="Recipient"
            reference="customers"
            source="recipient.id"
            link="show"
          >
            <TextField source="firstName" />
          </ReferenceField>
          <TextField label="Origin" source="origin.name" />
          <TextField label="Destination" source="destination.name" />
          <TextField label="Status" source="status.value" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Received Consignments"
        reference="consignments"
        target="recipient.id"
      >
        <Datagrid>
          <DateField source="date" />
          <ReferenceField
            label="Sender"
            reference="customers"
            source="sender.id"
            link="show"
          >
            <TextField source="firstName" />
          </ReferenceField>
          <TextField label="Origin" source="origin.name" />
          <TextField label="Destination" source="destination.name" />
          <TextField label="Status" source="status.value" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const CustomerForm = (props) => (
  <div style={{ width: "100%" }}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        style={{ backgroundColor: "#b6b6b6", borderRadius: "10px" }}
      >
        <Typography variant="h6" gutterBottom>
          Customer information
        </Typography>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <TextInput source="firstName" validate={validateName} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="lastName" validate={validateName} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput source="emailAddress" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PhoneField
            placeholder="phone"
            name="phoneNumber"
            defaultValue={props.record.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput multiline source="bio" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="address" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="city" fullWidth />
        </Grid>
        <Grid item xs={12} style={{ display: "flex" }}>
          <CountryDropdownField name="country" placeholder="Country" />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput source="nearestLandmark" fullWidth />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export const CustomerCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The customer profile was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The customer profile was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Customer/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <CustomerForm />
      </SimpleForm>
    </Create>
  );
};

export const CustomerEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The customer profile was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The customer profile was not updated!", "warning");
    console.log(error);
  };

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Customer/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm
            {...props}
            record={record}
            toolbar={<SaveCompanyOnSaveToolbar />}
            warnWhenUnsavedChanges
          >
            <CustomerForm record={record} />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};

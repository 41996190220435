/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import SignIn from "../auth/Login";
import SignUp from "../auth/SignUp";
import SignOut from "../auth/SignOut";
import ProfilePage from "../auth/ProfilePage";
import { ProfileContext } from "../../providers/ProfileProvider";
import PasswordReset from "../auth/CustomForgotPassword";
import { theme } from "../../config/themeConfig";
import TwLayout from "../../layout/TwLayout.js";
import { Admin, Resource, Title } from "react-admin";
import { firebaseConfig } from "../../config/firebaseConfig";
import { rafOptions } from "../../config/rafConfig";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import ResourceName from "../../routes/resource-names";
import { ResourceWithPermissions } from "ra-auth-acl";
import { resource_name } from "../../routes/resource-names";
import CompanySignUp from "../auth/CompanySignUp";
import CircularProgress from "@material-ui/core/CircularProgress";

// models components
import Dashboard from "./dashboard";
import {
  CompanyList,
  CompanyShow,
  CompanyCreate,
  CompanyEdit,
} from "./companies";
import { HubList, HubShow, HubCreate, HubEdit } from "./hubs";
import { AdminList, AdminShow, AdminCreate, AdminEdit } from "./admins";
import { TeamList, TeamCreate, TeamEdit } from "./teams";
import {
  PermissionList,
  PermissionShow,
  PermissionCreate,
  PermissionEdit,
} from "./permissions";
import {
  CustomerList,
  CustomerShow,
  CustomerCreate,
  CustomerEdit,
} from "./customers";
import { AgentList, AgentShow, AgentCreate, AgentEdit } from "./agents";
import { VehicleList, VehicleShow, VehicleCreate, VehicleEdit } from "./fleet";
import { TripList, TripShow, TripCreate, TripEdit } from "./trips";
import ConsignmentList from "../../components/Consignments";
import {
  ConsignmentShow,
  ConsignmentCreate,
  ConsignmentEdit,
} from "./consignments";
import {
  PaymentList,
  PaymentShow,
  PaymentCreate,
  PaymentEdit,
} from "./payments";
import {
  BookingList,
  BookingShow,
  BookingCreate,
  BookingEdit,
} from "./bookings";
import {
  DispatchList,
  DispatchShow,
  DispatchCreate,
  DispatchEdit,
} from "./dispatches";
import { RouteList, RouteShow, RouteCreate, RouteEdit } from "./routes";
import {
  MessageList,
  MessageShow,
  MessageCreate,
  MessageEdit,
} from "./messages";
import { AccountList } from "./account";
import {
  AddressList,
  AddressShow,
  AddressEdit,
  AddressCreate,
} from "./address";
import { StatusEdit, StatusCreate, StatusList, StatusShow } from "./status";
import {
  WayBillList,
  WayBillShow,
  WayBillCreate,
  WayBillEdit,
} from "./waybills";
import { RatingList, RatingShow } from "./ratings";
import {
  makeStyles,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

/**** icons ****/
// We are using aliases for imports [https://stackoverflow.com/questions/39282253/how-can-i-alias-a-default-import-in-javascript]
// so that we can stick to a defined Icon ref name independent of the icon we are importing
// This helps in that to change an icon, only the import statement is changed while the icon ref remains the same
import CompanyIcon from "@material-ui/icons/Business"; // companies
import HubIcon from "@material-ui/icons/AmpStories"; // hubs
import AdminIcon from "@material-ui/icons/SupervisorAccount"; // admins
import TeamIcon from "@material-ui/icons/AssignmentInd"; // teams
import PermissionIcon from "@material-ui/icons/Security"; // permissions
import CustomerIcon from "@material-ui/icons/PeopleOutline"; // customers
import AgentIcon from "@material-ui/icons/DriveEta"; // agents
import FleetIcon from "@material-ui/icons/Commute"; // fleet
import TripIcon from "@material-ui/icons/AirportShuttle"; // trips
import ConsignmentIcon from "@material-ui/icons/CardTravel"; // consignments
import PaymentIcon from "@material-ui/icons/LocalAtm"; // payments
import BookingIcon from "@material-ui/icons/Dashboard"; // bookings
import DispatchIcon from "@material-ui/icons/PresentToAll"; // dispatches
import RouteIcon from "@material-ui/icons/AccountTree"; // routes
import MessageIcon from "@material-ui/icons/MailOutline"; // messages
import AccountIcon from "@material-ui/icons/AccountBalanceWallet"; // accounts
import StatusIcon from "@material-ui/icons/LocalActivity"; // status
import WayBillIcon from "@material-ui/icons/ReceiptOutlined"; // waybill
import AddressIcon from "@material-ui/icons/AddLocation"; // address
import RatingsIcon from "@material-ui/icons/RateReview"; // ratings
import ValidateInvite from "../auth/ValidateInvite";
import Link from "../../components/StyledLink";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";

const useStyles = makeStyles({
  title: {
    flex: 1,
    marginRight: "20px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 28,
  },
});

const i18nMessages = { en: englishMessages };

function TwAdmin() {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const dataProvider = FirebaseDataProvider(firebaseConfig, rafOptions);
  let authProvider = FirebaseAuthProvider(firebaseConfig, rafOptions);
  const i18nProvider = polyglotI18nProvider(
    (locale) => i18nMessages[locale],
    "en",
    { allowMissing: true }
  );

  const [state, setState] = useState({
    showDashboard: false,
    showLoader: false,
    showSignin: false,
  });

  const { showDashboard, showLoader, showSignin } = state;
  // check for cached profile data
  let cachedProfile = localStorage.getItem("profile");
  cachedProfile = JSON.parse(cachedProfile);

  let handleGetPermissions = async () => await profile.team.permissions;

  const { permissions, getUserPerms } = useFetchCurrentUserPermissions();

  React.useEffect(() => {
    handleGetPermissions = getUserPerms;

    if (profile.admin) {
      setState((state) => ({
        ...state,
        showDashboard: true,
        showLoader: false,
      }));
    } else if (cachedProfile && cachedProfile.admin) {
      setState((state) => ({
        ...state,
        showDashboard: false,
        showLoader: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        showSignin: true,
      }));
      // await navigate("/signin");
    }

    console.log("********* profile *************", profile);
    console.log("********* cachedProfile *************", cachedProfile);
  }, [profile]);

  const handleCheckError = async (error) => {
    // method to override authProvider's checkError
    // forces a logout only for 401 (unauthorised) errors
    // alerts user that s/he lacks permission for 403 (forbidden) errors
    const status = error.status;

    if (status === 401) {
      return Promise.reject();
    } else if (status === 403) {
      alert("You lack permission to access the requested resource");
    }
  };

  // override these default authProvider methods with custom ones
  authProvider.getPermissions = handleGetPermissions;
  authProvider.checkError = handleCheckError;
  const classes = useStyles();
  const resource_name = new ResourceName(companyId).resourceNameObj;

  if (profile.admin) {
    // refresh page to enable offline persistence
    let setOfflinePersistence = localStorage.getItem("setOfflinePersistence");
    if (setOfflinePersistence) {
      localStorage.removeItem("setOfflinePersistence"); // to prevent infinite reload loop
      // reload to force re-execution of firebaseConfig.js thereby setting offline persistence
      location.reload();
    }
  }

  // all is set, return admin dashboard
  return (
    <div>
      {showDashboard && (
        <Admin
          theme={theme}
          layout={TwLayout}
          loginPage={SignIn}
          logoutButton={SignOut}
          dashboard={Dashboard}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
        >
          {(permissions) => [
            <Title
              title={<Link to="/">{profile.company.name}</Link>}
              className={classes.title}
            />,
            <Resource name="dashboard" />,

            <ResourceWithPermissions
              name={resource_name.companies}
              permissions={permissions}
              options={{ label: "Companies" }}
              icon={CompanyIcon}
              list={CompanyList}
              show={CompanyShow}
              create={CompanyCreate}
              edit={CompanyEdit}
            />,
            <ResourceWithPermissions
              name={resource_name.hubs}
              permissions={permissions}
              options={{ label: "Hubs" }}
              icon={HubIcon}
              list={HubList}
              show={HubShow}
              create={HubCreate}
              edit={HubEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.admins}
              permissions={permissions}
              options={{ label: "Admins" }}
              icon={AdminIcon}
              list={AdminList}
              show={AdminShow}
              create={AdminCreate}
              edit={AdminEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.teams}
              permissions={permissions}
              options={{ label: "Teams" }}
              icon={TeamIcon}
              list={TeamList}
              create={TeamCreate}
              edit={TeamEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.permissions}
              permissions={permissions}
              options={{ label: "Permissions" }}
              icon={PermissionIcon}
              list={PermissionList}
              show={PermissionShow}
              create={PermissionCreate}
              edit={PermissionEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.customers}
              permissions={permissions}
              options={{ label: "Customers" }}
              icon={CustomerIcon}
              list={CustomerList}
              show={CustomerShow}
              create={CustomerCreate}
              edit={CustomerEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.agents}
              permissions={permissions}
              options={{ label: "Agents" }}
              icon={AgentIcon}
              list={AgentList}
              show={AgentShow}
              create={AgentCreate}
              edit={AgentEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.fleet}
              permissions={permissions}
              options={{ label: "Fleet" }}
              icon={FleetIcon}
              list={VehicleList}
              show={VehicleShow}
              create={VehicleCreate}
              edit={VehicleEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.trips}
              permissions={permissions}
              options={{ label: "Trips" }}
              icon={TripIcon}
              list={TripList}
              show={TripShow}
              create={TripCreate}
              edit={TripEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.consignments}
              permissions={permissions}
              options={{ label: "Consignments" }}
              icon={ConsignmentIcon}
              list={ConsignmentList}
              show={ConsignmentShow}
              create={ConsignmentCreate}
              edit={ConsignmentEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.payments}
              permissions={permissions}
              options={{ label: "Payments" }}
              icon={PaymentIcon}
              list={PaymentList}
              show={PaymentShow}
              create={PaymentCreate}
              edit={PaymentEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.bookings}
              permissions={permissions}
              options={{ label: "Bookings" }}
              icon={BookingIcon}
              list={BookingList}
              show={BookingShow}
              create={BookingCreate}
              edit={BookingEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.dispatches}
              permissions={permissions}
              options={{ label: "Dispatches" }}
              icon={DispatchIcon}
              list={DispatchList}
              show={DispatchShow}
              create={DispatchCreate}
              edit={DispatchEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.routes}
              permissions={permissions}
              options={{ label: "Routes" }}
              icon={RouteIcon}
              list={RouteList}
              show={RouteShow}
              create={RouteCreate}
              edit={RouteEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.messages}
              permissions={permissions}
              options={{ label: "Messages" }}
              icon={MessageIcon}
              list={MessageList}
              show={MessageShow}
              create={MessageCreate}
              edit={MessageEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.accounts}
              permissions={permissions}
              options={{ label: "Account" }}
              icon={AccountIcon}
              list={AccountList}
            />,

            <ResourceWithPermissions
              name={resource_name.address}
              permissions={permissions}
              options={{ label: "Address" }}
              icon={AddressIcon}
              list={AddressList}
              show={AddressShow}
              create={AddressCreate}
              edit={AddressEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.status}
              options={{ label: "Status" }}
              icon={StatusIcon}
              list={StatusList}
              show={StatusShow}
              create={StatusCreate}
              edit={StatusEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.waybills}
              permissions={permissions}
              options={{ label: "Waybills" }}
              icon={WayBillIcon}
              list={WayBillList}
              show={WayBillShow}
              create={WayBillCreate}
              edit={WayBillEdit}
            />,

            <ResourceWithPermissions
              name={resource_name.ratings}
              permissions={permissions}
              options={{ label: "Ratings" }}
              icon={RatingsIcon}
              list={RatingList}
              show={RatingShow}
            />,
          ]}
        </Admin>
      )}
      {showLoader && (
        <div style={{ marginTop: "25%", marginLeft: "40%" }}>
          <CircularProgress variant="indeterminate" size="10%" />
        </div>
      )}
      {showSignin && !showDashboard && !showLoader && <SignIn />}
    </div>
  );
}

export default TwAdmin;

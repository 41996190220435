import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import { db } from "../../config/firebaseConfig";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  Filter,
  DateField,
  useRedirect,
  useNotify,
  ReferenceManyField,
  useMutation,
  AutocompleteInput,
  ReferenceField,
  FunctionField,
  useRefresh,
} from "react-admin";
import { validateRequired } from "../../utils/validators";
import { DateTimeInput } from "react-admin-date-inputs2";
import DateUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import SectionHeader from "../../components/SectionHeader";
import AgentQuickCreate from "./AgentQuickCreate";
import EmptyListPage from "../../components/EmptyListPage";
import DispatchIcon from "@material-ui/icons/PresentToAll";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import ResourceName from "../../routes/resource-names";
import SelectConsignments from "../../components/Dispatches/components/SelectConsignments";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import parseDateTime from "../../utils/parseDateTime";
import { DateTime } from "luxon";
import DispatchEditToolbar from "../../components/Dispatches/components/DispatchesEditToolBar";
import { createOrUpdateADispatch } from "../../components/Dispatches/components/dispatchHelpers";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";

// consIdsToBeAddedToDispatchCreate - a list of cons ids to be dispatched.
let consIdsToBeAddedToDispatchCreate = [];

// consIdsToBeAddedToDispatch - a list of cons ids to be dispatched on edit.
let consIdsToBeAddedToDispatch = [];

//a list of cons ids to be removed from a dispatch
let consIdsToBeRemovedFromDispatch = [];

const fetchUnassignedCons = async (companyId) => {
  // unassignedConDocs - empty to begin with.
  const unassignedConsDocs = [];

  const consRef = db
    .collection("consignments")
    .where("companyId", "==", companyId);
  try {
    const querySnapshot = await consRef.get();
    querySnapshot.forEach((doc) => {
      // get consignments that belong to the current company and don't have a consignment.dispatchId.
      if (!doc.data().dispatchId) {
        // unassigned consignment
        const unassignedConsDoc = doc.data();
        // get an unassigned consignment's id
        unassignedConsDoc.id = doc.id;

        // push unassigned consignment to unassignedConDocs
        unassignedConsDocs.push(unassignedConsDoc);
      }
    });
  } catch (err) {
    console.error("*** err fetching unassigned cons ****", err);
  }
  return unassignedConsDocs;
};

const renderAgentName = (agent) => {
  if (agent === null) {
    // return the agent as it is for the create-new modal to work
    return agent;
  }
  if (agent.firstName === undefined || agent.firstName === null) {
    // return 'Create New' to be displayed for the option where you'd like to
    // create a new agent instead of choosing one of the selected ones
    return "Create New";
  } else {
    return `${agent.firstName} ${agent.lastName}`;
  }
};

const noConsignmentsMessage = `You have no consignments to dispatch. Either all of your consignments have been dispatched
or you don't have new consignments. Please create consignments first.`;

const NoConsignments = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginBottom: "0.8rem",
    }}
  >
    <div>{noConsignmentsMessage}</div>
    <Link to="/consignments">
      <Button variant="contained" color="primary">
        Create Consignments
      </Button>
    </Link>
  </div>
);

const DispatchesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="routeId" alwaysOn />
    <TextInput label="Route" source="route" />
  </Filter>
);

export const DispatchList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const resourceName = new ResourceName(companyId).resourceNameObj;

  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const dispatchesExporter = (dispaches) =>
    exporter({
      resourceArr: dispaches,
      companyId: companyId,
      resourceType: "dispatches",
    });
  // watch the collection and refresh the view if any data changes
  const refresh = useRefresh();
  db.collection("dispatches").onSnapshot((snap) => {
    console.log("~~~~ dispatches changed! ~~~");
    refresh();
  });

  return companyId ? (
    <List
      {...props}
      sort={{ field: "date", order: "DESC" }}
      title="Dispatches"
      filters={<DispatchesFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={DispatchIcon} />}
      exporter={
        formattedPermissions.dispatches && formattedPermissions.dispatches.list
          ? dispatchesExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <DateField source="date" label="EDD" />
        <TextField source="consignmentsCount" label="Consignments" />
        <ReferenceField
          label="Agent"
          reference={resourceName.agents}
          source="agent.id"
          link="show"
        >
          <FunctionField
            label="Agent"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceField>
        <TextField source="origin" />
        <TextField source="destination" />
        {props.permissions && props.permissions.dispatches.edit && (
          <EditButton />
        )}
        {props.permissions && props.permissions.dispatches.delete && (
          <DeleteButton undoable={false} />
        )}
      </Datagrid>
    </List>
  ) : null;
};

export const DispatchShow = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const resourceName = new ResourceName(companyId).resourceNameObj;

  return (
    <Show {...props} title="Dispatch">
      <SimpleShowLayout>
        <SectionHeader text="Dispatch Information" />
        <ReferenceField
          label="Agent"
          reference={resourceName.agents}
          source="agent.id"
          link="show"
        >
          <FunctionField
            label="Agent"
            render={(record) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceField>
        <TextField source="vehicle" label="Vehicle" />
        <TextField source="consignmentsCount" label="Consignments" />
        <TextField source="date" label="Dispatch Date" />
        <TextField source="origin" label="Origin" />
        <TextField source="destination" label="Destination" />
        <ReferenceManyField
          label="Consignments"
          reference="consignments"
          target="dispatchId"
        >
          <Datagrid>
            <TextField source="trackingCode" />
            <TextField source="origin.name" label="Origin" />
            <TextField source="destination.name" label="Destination" />
            <TextField source="status.value" label="Status" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export const DispatchCreate = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  const redirect = useRedirect();
  const notify = useNotify();

  const [unassignedConsignments, setUnassignedConsignments] = useState([]);
  const [agents, setAgents] = useState([]);

  const [showDialog, setShowDialog] = useState(false);

  const unassignedHelperText =
    "These are the available orders that are yet to be fulfilled. Please select one or more to add consignments that will be handled in this dispatch";
  const assignedHelperTxt = `These are the orders that will be fulfilled. You can add more or remove items from this list any time`;

  // function to make on demand queries e.g update request
  // these are queries that don't run when component loads but rather when called/required
  const [makeQuery] = useMutation();

  async function fetchData(collectionUrl) {
    // DRY function to fetch data from a collection url
    let querySnapshot = await db.collection(collectionUrl).get();
    const data = querySnapshot.docs.map((doc) => {
      // create object using doc data and id
      let obj = doc.data();
      obj.id = doc.id;
      console.log("AGENT-OBJ: ", obj);
      return obj;
    });
    return data;
  }

  useEffect(() => {
    // set agents
    const collectionUrl = `companies/${companyId}/agents`;
    fetchData(collectionUrl).then((data) => {
      setAgents(data);
    });
  }, [showDialog, companyId]);

  useEffect(() => {
    // set vehicles
    const collectionUrl = `companies/${companyId}/fleet`;
    fetchData(collectionUrl).then((data) => {
      return data;
    });
  }, [companyId]);

  useEffect(() => {
    // fetch unassigned consignments from the db
    const getUnassignedConsignments = () => {
      // update state.unassignedConsignments with [unassignedConDocs]
      fetchUnassignedCons(companyId).then((res) =>
        setUnassignedConsignments(res)
      );
    };

    getUnassignedConsignments();
  }, [companyId]);

  function handleValueChange(value) {
    console.log("VALUE", value);
    if (value === "create-new") {
      setShowDialog(true);
    }
  }

  function onDialogClosed() {
    setShowDialog(false);
  }

  const getAssignedConsignments = (assignedCons) => {
    if (assignedCons !== undefined && assignedCons.length) {
      // obtain a list of ids from assignedCons
      const assignedConsIds = assignedCons.map((consignment) => consignment.id);
      // update consIdsToBeAddedToDispatchCreate with the ids in assignedConsIds
      consIdsToBeAddedToDispatchCreate = [...assignedConsIds];
    }
  };

  const updateDispatchCons = (dispatchId) => {
    const updateData = {
      dispatchId: dispatchId,
      status: {
        value: "dispatched",
      },
    };
    makeQuery(
      {
        type: "updateMany",
        resource: "consignments",
        payload: { ids: consIdsToBeAddedToDispatchCreate, data: updateData },
      },
      {
        onSuccess: ({ data }) => {
          console.log(
            "****** dispatch consignments have been successfully created *************",
            data
          );
          notify("The dispatch was created successfully", "success");
          redirect("list", props.basePath);
        },
      },
      {
        onFailure: (error) => {
          console.error(
            "******* an error when updating consignments *******",
            error
          );
          notify(
            "Something went wrong when updating the consignments tied to your dispatch",
            "warning"
          );
        },
      }
    );
  };

  const handleDispatchSave = (values) => {
    values._last_consignments_added = consIdsToBeAddedToDispatchCreate;
    // add companyId to fix FirebaseError: PERMISSION_DENIED: Property companyId is undefined on object
    values.companyId = companyId;

    const payload = { ...values };

    /**
     * on success, update dispatch cons with the created dispatch.id
     */
    const handleSuccess = (dispatchId) => {
      console.log(
        "*** your dispatch was successfully created ******",
        dispatchId
      );
      updateDispatchCons(dispatchId);
    };

    const handleError = (err) =>
      console.error(
        "**** something went wrong when creating your dispatch ******",
        err
      );

    createOrUpdateADispatch({
      method: "create",
      payload: payload,
      successCallback: handleSuccess,
      failureCallback: handleError,
      dispatchId: null,
    });
  };

  return (
    <>
      {showDialog && (
        <AgentQuickCreate
          defaultState={showDialog}
          onDialogClosed={onDialogClosed}
          companyId={companyId}
        />
      )}
      <Create {...props} undoable={false} title="Dispatch/Create">
        <SimpleForm
          toolbar={<DispatchEditToolbar handleSave={handleDispatchSave} />}
          warnWhenUnsavedChanges
        >
          <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SectionHeader text="Dispatch details" />
              </Grid>
              <Grid container spacing={2} item xs={12} sm={6}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    source="origin"
                    label="Origin/Pickup"
                    placeholder="e.g, Nairobi"
                    validate={validateRequired}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    source="destination"
                    label="Destination/Dropoff"
                    placeholder="e.g, Mombasa"
                    validate={validateRequired}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MuiPickersUtilsProvider utils={DateUtils}>
                    <DateTimeInput
                      source="date"
                      label="Dispatch Date"
                      validate={validateRequired}
                      parse={parseDateTime}
                      options={{
                        format: "DD/MM/YYYY, HH:mm:ss",
                        ampm: false,
                        clearable: true,
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <AutocompleteInput
                    source="agent.id"
                    label="Agent"
                    choices={agents}
                    allowEmpty={true}
                    emptyText="Create New"
                    emptyValue="create-new"
                    optionText={renderAgentName}
                    optionValue="id"
                    onChange={handleValueChange}
                    validate={validateRequired}
                    inputProps={{
                      form: {
                        autocomplete: "off",
                      },
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* select consignments to be part of a dispatch */}
              <Grid container spacing={2} item xs={12} sm={12}>
                {!unassignedConsignments.length && <NoConsignments />}
              </Grid>
              <Grid container spacing={2} item xs={12} sm={6}>
                {unassignedConsignments.length ? (
                  <SelectConsignments
                    initialSource={unassignedConsignments}
                    sourceHeader="Unassigned"
                    targetHeader="Assigned"
                    sendTargetToParent={getAssignedConsignments}
                    sourceHelperText={unassignedHelperText}
                    targetHelperText={assignedHelperTxt}
                  />
                ) : null}
              </Grid>
              <HiddenTextInput source="route" initialValue={[{}, {}]} />{" "}
              {/* TODO: implement route population */}
            </Grid>
          </div>
        </SimpleForm>
      </Create>
    </>
  );
};

export const DispatchEdit = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  const redirect = useRedirect();
  const notify = useNotify();

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(new Date());
  const [agent, setAgent] = useState(null);
  const [agents, setAgents] = useState([]);
  const [dispatchConsignments, setDispatchConsignments] = useState([]);
  let [unassignedConsignments, setUnassignedConsignments] = useState([]);
  let [assignedConsignments, setAssignedConsignments] = useState([]);
  const [showAgentsModal, setShowAgentsModal] = useState(false);
  const [isFetchingUnassignedCons, setIsFetchingUnassignedCons] = useState(
    false
  );
  const [isFetchingDispatchCons, setIsFetchingDispatchCons] = useState(false);

  const unassignedHelperText =
    "These are the available orders that are yet to be fulfilled. Please select one or more to add consignments that will be handled in this dispatch";
  const assignedHelperTxt = `These are the orders that will be fulfilled by ${
    agent !== null && agent.firstName
  }  ${
    agent !== null && agent.lastName
  } on ${expectedDeliveryDate}. You can add more or remove items from this list any time`;

  // function to make on demand queries e.g update request
  // these are queries that don't run when component loads but rather when called/required
  const [mutate] = useMutation();

  async function fetchData(collectionUrl) {
    // DRY function to fetch data from a collection url
    let querySnapshot = await db.collection(collectionUrl).get();
    const data = querySnapshot.docs.map((doc) => {
      // create object using doc data and id
      let obj = doc.data();
      obj.id = doc.id;
      console.log("AGENT-OBJ: ", obj);
      return obj;
    });
    return data;
  }

  useEffect(() => {
    // set agents
    const collectionUrl = `companies/${companyId}/agents`;
    fetchData(collectionUrl).then((data) => {
      setAgents(data);
    });
  }, [showAgentsModal, companyId]);

  useEffect(() => {
    // set vehicles
    const collectionUrl = `companies/${companyId}/fleet`;
    fetchData(collectionUrl).then((data) => {
      return data;
    });
  }, [companyId]);

  useEffect(() => {
    const getUnassignedConsignments = () => {
      setIsFetchingUnassignedCons(true);
      fetchUnassignedCons(companyId).then((res) => {
        setUnassignedConsignments(res);
        setIsFetchingUnassignedCons(false);
      });
    };

    const fetchAssignedConsignments = () => {
      db.collection("consignments")
        .where("companyId", "==", companyId)
        .get()
        .then((querySnapshot) => {
          const assignedCons = [];
          querySnapshot.forEach((doc) => {
            // do we have consignments that are attached to this dispatch?
            if (doc.data().dispatchId === props.match.params.id) {
              const assignedConsDoc = doc.data();
              assignedConsDoc.id = doc.id;

              assignedCons.push(assignedConsDoc);
            }
          });

          setAssignedConsignments(assignedCons);
          setDispatchConsignments(assignedCons);
        })
        .catch((err) =>
          console.log("************ err: fetching assigned cons *******", err)
        );
    };

    getUnassignedConsignments();
    fetchAssignedConsignments();
  }, [companyId, props.match.params.id]);

  useEffect(() => {
    const getCurrentDispatch = async () => {
      setIsFetchingDispatchCons(true);
      const dispatchRef = db
        .collection("dispatches")
        .doc(props.match.params.id);
      try {
        const querySnapshot = await dispatchRef.get();
        setIsFetchingDispatchCons(false);
        const agentId = querySnapshot.data().agent.id;
        const agentData = agents.length
          ? agents.filter((agent) => agent.id === agentId)[0]
          : {};
        // set agent
        setAgent(agentData);

        // Get dispatch.date which is of type Object: Firestore-Timestamp and convert it to js date
        // seeing that luxon produces invalid date when we use a firestore timestamp.
        // This assumes that all dates are Firestore Timestamps as we aready have a migration script to do the convertion
        const dispatchDate = querySnapshot.data().date.toDate();

        // use luxon to format date
        // @see - https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html
        const expectedDelDate = DateTime.fromJSDate(dispatchDate)
          .setZone("Africa/Nairobi")
          .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

        // set state.expectedDeliveryDate
        setExpectedDeliveryDate(expectedDelDate);
      } catch (err) {
        console.error("**** err when fetching dispatch *******", err);
        setIsFetchingDispatchCons(false);
      }
    };

    getCurrentDispatch();
  }, [agents, props.match.params.id]);

  const handleAutoCompleteChange = (value) => {
    value === "Create New" ? setShowAgentsModal(true) : setAgent(value);
  };

  const closeAgentsModal = () => {
    setShowAgentsModal(false);
  };

  /**
   * compute the delta on the assigned list so as to persist only the cons that changed
   * @param {*} assignedCons - assigned cons from the picklist component
   */
  const getAssignedConsignments = (assignedCons) => {
    if (assignedCons !== undefined && assignedCons.length) {
      // track cons that moved from left to right
      const consMovedLeftToRight = [];

      // obtain a list of ids of all the assigned cons in the current dispatch
      const listOfAssignedConsignmentsIds = assignedConsignments.map(
        (cons) => cons.id
      );

      // track change in assigned cons, i.e, get assigned cons that are in the picklist but are not in the company
      assignedCons.forEach((cons) => {
        // if we have a cons in the picklist that is not part of company.assigned cons
        if (!listOfAssignedConsignmentsIds.includes(cons.id)) {
          // allow for the assignment call to be made
          localStorage.setItem("makeAssigningCall", "true");
          consMovedLeftToRight.push(cons.id);
        } else {
          localStorage.removeItem("makeAssigningCall");
        }
      });

      // update dispatchCandidatesCreate with the cons whose dispatchId is to be set to dispatch.id
      consIdsToBeAddedToDispatch = [...consMovedLeftToRight];
    }
  };

  /**
   * compute the delta on the unassigned list so as to persist only the cons that changed
   * @param {Array} unassignedCons - unassigned cons from the picklist component
   */
  const getUnassignedCons = (unassignedCons) => {
    // track cons that moved from R -> L
    const consMovedRightToLeft = [];

    // obtain a list of unassigned consIds from the current dispatch
    const listOfUnassignedConsignmentsIds = unassignedConsignments.map(
      (cons) => cons.id
    );

    // track change in unassigned cons, i.e, get the unassigned cons that are in the picklist but are not in company.unassigned cons
    unassignedCons.forEach((cons) => {
      if (!listOfUnassignedConsignmentsIds.includes(cons.id)) {
        localStorage.setItem("MakeUnassigningCall", "true");
        consMovedRightToLeft.push(cons.id);
      } else {
        localStorage.removeItem("MakeUnassigningCall");
      }
    });

    // update consIdsToBeRemovedFromDispatch with consIds whose dispatchId is to be set to null
    consIdsToBeRemovedFromDispatch = [...consMovedRightToLeft];
  };

  const updateAssignedConsignments = (
    dispatchUpdatePayload,
    handleSuccess,
    handleError
  ) => {
    mutate(
      {
        type: "updateMany",
        resource: "consignments",
        payload: {
          ids: consIdsToBeAddedToDispatch, // consIdsToBeAddedToDispatch
          data: { dispatchId: props.match.params.id },
        },
      },
      {
        onSuccess: ({ data }) => {
          console.log("%%%%%% success updating assigned cons %%%%%%%%", data);

          // update dispatch
          createOrUpdateADispatch({
            method: "update",
            payload: dispatchUpdatePayload,
            successCallback: handleSuccess,
            failureCallback: handleError,
            dispatchId: props.match.params.id,
          });
        },
        onFailure: (error) =>
          console.error(
            "###### error when updating assigned cons ######",
            error
          ),
      }
    );
  };

  const updateUnassignedConsignments = (
    dispatchUpdatePayload,
    handleSuccess,
    handleError
  ) => {
    mutate(
      {
        type: "updateMany",
        resource: "consignments",
        payload: {
          ids: consIdsToBeRemovedFromDispatch,
          data: { dispatchId: null },
        },
      },
      {
        onSuccess: ({ data }) => {
          console.log(
            "!!!!!!!!! success updating UNASSIGNED CONS  !!!!!!!!!!!",
            data
          );

          if (
            JSON.stringify(localStorage.getItem("MakeAssigningCall")) !== null
          ) {
            updateAssignedConsignments(
              dispatchUpdatePayload,
              handleSuccess,
              handleError
            );
          }
        },
        onFailure: (error) =>
          console.error(
            "@@@@@@ error when updating UNASSIGNED CONS @@@@@@@@@",
            error
          ),
      }
    );
  };

  const handleDispatchSave = (values, redirectTo) => {
    values._last_consignments_added = consIdsToBeAddedToDispatch;
    values._last_consignments_removed = consIdsToBeRemovedFromDispatch;
    const payload = { ...values };

    /**
     * notify users of the success
     * and redirect back to the list view
     */
    const handleSuccess = () => {
      notify("The dispatch was successfully edited!", "success");
      redirect(redirectTo, props.basePath, props.match.params.id);
    };

    /**
     * notify users of the error
     * @param {Object} err
     */
    const handleError = (err) => {
      console.error(
        "***** an error occurred while updating a dispatch ****",
        err
      );

      // notify users
      notify(
        "An error occurred while updating the dispatch. Please try again later",
        "warning"
      );
    };

    // update consignments
    if (JSON.stringify(localStorage.getItem("MakeUnassigningCall")) !== null) {
      console.log(
        " ---------------> consIdsToberemovedfromdispatch: ",
        consIdsToBeRemovedFromDispatch
      );
      updateUnassignedConsignments(payload, handleSuccess, handleError);
    }
  };

  return (
    <Edit {...props} undoable={false} title="Dispatch/Edit">
      <SimpleForm
        toolbar={<DispatchEditToolbar handleSave={handleDispatchSave} />}
        warnWhenUnsavedChanges
      >
        <div style={{ width: "100%" }}>
          {showAgentsModal && (
            <AgentQuickCreate
              defaultState={showAgentsModal}
              onDialogClosed={closeAgentsModal}
              companyId={companyId}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader text="Dispatch details" />
            </Grid>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="origin"
                  label="Origin/Pickup"
                  placeholder="e.g, Nairobi"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  source="destination"
                  label="Destination/Dropoff"
                  placeholder="e.g, Mombasa"
                  validate={validateRequired}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <DateTimeInput
                    source="date"
                    label="Dispatch Date"
                    validate={validateRequired}
                    parse={parseDateTime}
                    options={{
                      format: "DD/MM/YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                    }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={12} sm={12}>
                <AutocompleteInput
                  source="agent.id"
                  label="Agent"
                  choices={agents}
                  allowEmpty={true}
                  emptyText="Create New"
                  emptyValue="Create New"
                  optionText={renderAgentName}
                  optionValue="id"
                  validate={validateRequired}
                  onChange={handleAutoCompleteChange}
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/*  --consignment tables-- */}
            {isFetchingUnassignedCons && isFetchingDispatchCons ? (
              <>Loading ....</>
            ) : !isFetchingDispatchCons &&
              !isFetchingUnassignedCons &&
              !unassignedConsignments.length &&
              !dispatchConsignments.length ? (
              <Grid container spacing={2} item xs={12} sm={12}>
                {/* show 'No Consignments' if we don't have both unassigned consignments
              and the current dispatch doesn't have any consignments */}
                <NoConsignments />
              </Grid>
            ) : !isFetchingDispatchCons &&
              !isFetchingUnassignedCons &&
              unassignedConsignments ? (
              <Grid container spacing={2} item xs={12} sm={6}>
                <SelectConsignments
                  initialSource={unassignedConsignments}
                  initialTarget={assignedConsignments}
                  sourceHeader="Unassigned"
                  targetHeader="Assigned"
                  sendTargetToParent={getAssignedConsignments}
                  sendSourceToParent={getUnassignedCons}
                  sourceHelperText={unassignedHelperText}
                  targetHelperText={assignedHelperTxt}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

import React from "react";
import styled from "styled-components";

const MerchantRequestOrder = () => {
  return (
    <Wrapper>
      <p>Request an order</p>
      <h2>Fill in the recipient's details</h2>
      <p>Recipient's information</p>
      <button>Select existing cutomer</button>
      <form>
        <label for="fname">Full name</label>
        <input type="text" id="fname" name="firstname" />
        <label for="num">Phone number</label>
        <input type="text" id="num" name="number" />
        <br />

        <label for="address">Address</label>
        <input type="text" id="address" name="address" />
        <label for="info">Additional information</label>
        <input type="text" id="info" name="information" />

        <input
          type="checkbox"
          id="save_details"
          name="save_customer_details"
          value="Save"
        />
        <label for="save_details">Save recipient’s details</label>
      </form>
      <button>Fill packages details</button>
      <button>Cancel</button>
    </Wrapper>
  );
};

export default MerchantRequestOrder;

const Wrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10rem;
  background-color: #fafafa;
`;

import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  FunctionField,
  EmailField,
  TextInput,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
  ReferenceField,
  AutocompleteInput,
  useQueryWithStore,
  Loading,
  Error,
} from "react-admin";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import ResourceName from "../../routes/resource-names";
import { validateName, validateRequiredEmail } from "../../utils/validators";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { HiddenTextInput } from "../../components/hiddenTextInput";
import EmptyListPage from "../../components/EmptyListPage";
import AdminIcon from "@material-ui/icons/SupervisorAccount";
import PhoneNumberField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import { db } from "../../config/firebaseConfig";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import UserCard from "../../layout/UserCard";

export const AdminList = (props) => {
  const profile = useContext(ProfileContext);
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const companyId = profile.company.id;
  const resourceName = new ResourceName(companyId).resourceNameObj;

  const adminsExporter = (admins) =>
    exporter({
      resourceArr: admins,
      companyId: companyId,
      resourceType: "admins ",
    });

  return companyId ? (
    <List
      {...props}
      title="Admins"
      empty={
        <EmptyListPage
          icon={AdminIcon}
          sort={{ field: "createdAt", order: "DESC" }}
        />
      }
      exporter={
        formattedPermissions[resourceName.admins] &&
        formattedPermissions[resourceName.admins].list
          ? adminsExporter
          : false
      }
    >
      <UserCard />
    </List>
  ) : null;
};

export const AdminShow = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const resourceName = new ResourceName(companyId).resourceNameObj;

  return companyId ? (
    <Show {...props} title="Admin">
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={(source) => `${source.firstName} ${source.lastName}`}
        />
        <EmailField source="emailAddress" />
        <TextField source="phoneNumber" />
        <TextField source="bio" />
        <TextField source="title" />
        <ReferenceField
          label="Team"
          reference={resourceName.teams}
          source="team.id"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="hub" />
      </SimpleShowLayout>
    </Show>
  ) : null;
};

const AdminForm = (props) => (
  <div style={{ width: "100%" }}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "#b6b6b6",
          borderRadius: "0.5em",
          marginRight: "1em",
          marginBottom: "2em",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Enter Admin information:
        </Typography>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12} sm={6}>
          <TextInput source="firstName" validate={validateName} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source="lastName" validate={validateName} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            source="emailAddress"
            fullWidth
            validate={validateRequiredEmail}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PhoneNumberField defaultValue={props.record.phoneNumber} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutocompleteInput
            source="team.id"
            label="Team"
            choices={props.choices}
            optionText={"name"}
            optionValue={"id"}
            validate={validateName}
            options={{ autoComplete: "none" }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput source="title" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput source="hub" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput multiline source="bio" fullWidth />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export const AdminCreate = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const adminId = profile.admin.id;
  const resourceName = new ResourceName(companyId).resourceNameObj;
  const redirect = useRedirect();
  const notify = useNotify();

  // load teams belonging to current company
  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: resourceName.teams,
    payload: {
      pagination: { page: 1, perPage: 25 },
      sort: { field: "name", order: "ASC" },
      filter: {},
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const teams = data;

  const onSuccess = () => {
    notify("The admin user was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The admin user was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Admin/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <AdminForm choices={teams} />
        <HiddenTextInput source="inviterId" initialValue={adminId} />
      </SimpleForm>
    </Create>
  );
};

export const AdminEdit = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;
  const redirect = useRedirect();
  const notify = useNotify();
  const [state, setState] = React.useState({
    teams: [],
  });

  React.useEffect(() => {
    const fetchTeams = async () => {
      const teamsData = await (
        await db.collection(`companies/${companyId}/teams`).get()
      ).docs;
      teamsData.forEach((teamData) => {
        const team = teamData.data();
        team.id = teamData.id;
        setState((state) => ({
          ...state,
          teams: [...state.teams, team],
        }));
      });
    };

    fetchTeams();
  }, [companyId]);

  const onSuccess = () => {
    notify("The admin user was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The admin user was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Admin/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} warnWhenUnsavedChanges>
            <AdminForm record={record} choices={state.teams} />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};

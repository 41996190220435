/* eslint-disable */
import * as React from "react";
import { AppBar, UserMenu } from "react-admin";
import ProfilePage from "../pages/auth/ProfilePage";
import MenuItem from "@material-ui/core/MenuItem";
import { ProfileContext } from "../providers/ProfileProvider";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const TwCustomIconStyle = {
  avatar: {
    height: 40,
    width: 40,
  },
};

const TwCustomIcon = withStyles(TwCustomIconStyle)(({ classes, user }) => (
  <Avatar className={classes.avatar} src={user.photoURL} />
));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.tertiary.main,
  },
}));

const TwUserMenu = (props) => (
  <UserMenu {...props} icon={<TwCustomIcon user={props.user} />}>
    <MenuItem>
      {" "}
      <ProfilePage />{" "}
    </MenuItem>
  </UserMenu>
);

const TwAppBar = (props) => {
  const profile = React.useContext(ProfileContext);
  const user = profile.authUser;
  const { photoURL, displayName, email } = user;
  const classes = useStyles();

  return (
    <AppBar
      {...props}
      userMenu={<TwUserMenu user={user} />}
      className={classes.root}
    />
  );
};

export default TwAppBar;

import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  ArrayInput,
  SimpleFormIterator,
  useRedirect,
  useNotify,
} from "react-admin";

export const RouteList = (props) => (
  <List {...props} title="Routes">
    <Datagrid rowClick="show">
      <TextField source="standard_charge" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export const RouteShow = (props) => (
  <Show {...props} title="Route">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="standard_charge" />
      <ArrayField source="locations">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const RouteForm = () => (
  <SimpleForm warnWhenUnsavedChanges>
    <TextInput source="standard_charge" />
    <ArrayInput source="backlinks">
      <SimpleFormIterator>
        <TextInput source="name" />
        <TextInput source="place_id" />
        <TextInput source="route_order" />
        <TextInput source="type" />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)

export const RouteCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The route was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The route was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Route/Create"
    >
      <RouteForm />
    </Create>
  );
};

export const RouteEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The route was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The route was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Route/Show"
    >
      <RouteForm />
    </Edit>
  );
};

export default {
  consignmentStatusArray: [
    {
      STATUS: "created",
      MSG:
        "Someone on your team has recorded an incoming parcel/consignment request (or, a sender has generated request to have their item shipped",
    },
    {
      STATUS: "dispatched",
      MSG:
        "The item has been assigned to a delivery agent and/or is in transit to the destination",
    },
    {
      STATUS: "delivered",
      MSG:
        "The item has arrived at the final destination and is ready for pickup (if you do not offer door-to-door services) or has reached the recipient (if you offer D2D services)",
    },
    {
      STATUS: "collected",
      MSG:
        "(if you do not offer door-to-door services) the item has been picked up by the recipient at the drop-off point",
    },
  ],
};

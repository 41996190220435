/* eslint-disable */
import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

export const db = firebase.firestore();

export const createOrUpdateUser = async (admin, additionalData) => {
  if (!admin) return;

  const adminRef = db.doc(`admins/${admin.uid}`); //make this admins.change variable names
  const snapshot = await adminRef.get();

  if (!snapshot.exists) {
    const { email, displayName, photoURL } = admin;
    try {
      await adminRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating admin document", error);
    }
  }
  return getAdminDocument(admin.uid);
};

const getAdminDocument = async (uid) => {
  if (!uid) return null;
  try {
    const adminDocument = await db.doc(`admins/${uid}`).get();

    return {
      uid,
      ...adminDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching admin", error);
  }
};

import React, { useContext } from "react";
import { Toolbar, SaveButton } from "react-admin";
import PropTypes from "prop-types";
import { ProfileContext } from "../../../../providers/ProfileProvider";

const DispatchEditToolbar = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  // for more info about the Toolbar props @see: https://marmelab.com/react-admin/CreateEdit.html#toolbar
  const { handleSave } = props;
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={(data) => ({ ...data, companyId: companyId })}
        onSave={handleSave}
      />
    </Toolbar>
  );
};

DispatchEditToolbar.propTypes = {
  // pristine: indicates whether the form inputs have been changed yet -> might come in handy when we need to disable the submit button
  pristine: PropTypes.bool,
  invalid: PropTypes.bool, // indicates whether the form is valid or not
  saving: PropTypes.bool, // indicates whether the form is still being submitted
  handleSave: PropTypes.func.isRequired, // handles what happens when we hit save
};

export default DispatchEditToolbar;

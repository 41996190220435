import React from "react";
import CardActions from "@material-ui/core/CardActions";
import { DeleteButton, EditButton } from "react-admin";
import PropTypes from "prop-types";

import {
  UserProfileWrapper,
  UserCardWrapper,
  CardContentWrapper,
  UserPicWrapper,
  AvatarWrapper,
  UserName,
  UserEmailAddress,
  UserPhoneNumber,
} from "./styles";

//ids = is an array of the ids currently displayed in the list
// data = is an object of all the fetched data for this resource, indexed by id.
//basePath = deduced from the location, useful for action buttons

const UserCard = ({ ids, data, basePath }) => (
  <UserProfileWrapper>
    {ids.map((id) => (
      <UserCardWrapper>
        <CardContentWrapper>
          <UserPicWrapper>
            <AvatarWrapper src="" />
          </UserPicWrapper>
          <UserName
            record={data[id]}
            render={(source) => `${source.firstName} ${source.lastName}`}
          />
          <UserEmailAddress record={data[id]} source="emailAddress" />
          <UserPhoneNumber record={data[id]} source="phoneNumber" />
          <CardActions>
            <EditButton
              resource={basePath}
              basePath={basePath}
              record={data[id]}
            />
            <DeleteButton
              resource={basePath}
              basePath={basePath}
              record={data[id]}
            />
          </CardActions>
        </CardContentWrapper>
      </UserCardWrapper>
    ))}
  </UserProfileWrapper>
);
UserCard.defaultProps = {
  data: {},
  ids: [1, 2],
};
UserCard.propTypes = {
  ids: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};
export default UserCard;

import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { TextField, FunctionField, EmailField } from "react-admin";

//UserProfile
export const UserProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr;
  grid-gap: 4rem 0.5rem;
  justify-content: center;
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;

  @media (min-width: 650px) {
    grid-template-columns: repeat(3, 2fr);
    margin: 1rem 0 1rem 0;
  }
`;

//override MUI Card
export const UserCardWrapper = styled(Card)`
  margin: auto 2rem;
`;

//override MUI CardContent
export const CardContentWrapper = styled(CardContent)`
  display: grid;
  gap: 1rem 0.2rem;
  justify-content: center;
  text-align: center;
`;

//Userpic wrapper
export const UserPicWrapper = styled.div`
  display: grid;
  justify-content: center;
`;

//override MUI Avatar
export const AvatarWrapper = styled(Avatar)`
  height: 5.5rem;
  width: 5.5rem;
`;

//override RA FunctionField
export const UserName = styled(FunctionField)`
  font-size: 1.1rem;
  font-weight: 600;
  color: black;
  letter-spacing: 0.1rem;
`;

//override RA EmailField
export const UserEmailAddress = styled(EmailField)`
  font-size: 1rem;
  font-weight: 400;
  color: grey;
  letter-spacing: 0.08rem;
`;

//override RA TextField
export const UserPhoneNumber = styled(TextField)`
  font-size: 0.9rem;
  font-weight: 400;
  color: grey;
  letter-spacing: 0.08rem;
`;

import React, { useContext } from "react";
import { useRedirect, useQueryWithStore, Loading, Error } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import { ProfileContext } from "../../../providers/ProfileProvider";
import ResourceName from "../../../routes/resource-names";

const paymentStatusStyles = makeStyles({
  root: {
    fontSize: "11px",
    textTransform: "none",
    backgroundColor: "transparent",
  },
});

const PaymentStatus = ({ consignmentId }) => {
  const redirect = useRedirect();
  const classes = paymentStatusStyles();
  const consignment = { id: consignmentId };
  const profile = useContext(ProfileContext);
  const resourceName = new ResourceName(profile.company.id).resourceNameObj;

  const { loaded, error, data } = useQueryWithStore({
    /* @param {bool} loaded: data loading state; false if data has not been loaded
     * @param {string} data[]: a list of payment objects
     * @param {string} error: null by default, set to value of query exception if one occures
     */
    type: "getList",
    resource: "payments",
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: {},
      filter: { consignment: consignment },
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  function redirectToPayment(event) {
    event.stopPropagation();
    event.preventDefault();

    // since payment and consignent have a one-to-one rltship, we expect one object in the list
    if (data[0]?.id) {
      redirect(`/${resourceName.payments}/${data[0]?.id}/show`);
    }
  }

  let paymentStatus = data[0]?.status || "not paid";

  return (
    <Button
      variant="outlined"
      className={classes.root}
      color="primary"
      onClick={(event) => redirectToPayment(event)}
      size="small"
    >
      {paymentStatus}
    </Button>
  );
};

PaymentStatus.propTypes = {
  consignmentId: PropTypes.string.isRequired,
};

export default PaymentStatus;

import React, { useContext } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  ReferenceField,
  FunctionField,
  Filter,
  TextInput,
} from "react-admin";
import ConsignmentIcon from "@material-ui/icons/CardTravel";

import { ProfileContext } from "../../providers/ProfileProvider";
import EmptyListPage from "../EmptyListPage";
import PaymentStatus from "./components/PaymentStatus";
import exporter from "../customDataExporter";
import useFetchCurrentUserPermissions from "../useFetchCurrentUserPermissions";

const ConsignmentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="trackingCode" alwaysOn />
    <TextInput label="Status" source="status" />
  </Filter>
);

const ConsignmentList = (props) => {
  const profile = useContext(ProfileContext);
  const companyId = profile.company.id;

  const { formattedPermissions } = useFetchCurrentUserPermissions();

  const consExporter = (consignments) =>
    exporter({
      resourceArr: consignments,
      companyId: companyId,
      resourceType: "consignments",
    });

  return companyId ? (
    <List
      {...props}
      sort={{ field: "date", order: "DESC" }}
      title="Consignments"
      filters={<ConsignmentsFilter />}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={ConsignmentIcon} />}
      exporter={
        formattedPermissions.consignments &&
        formattedPermissions.consignments.list
          ? consExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <DateField source="date" />
        <ReferenceField
          label="Sender"
          reference="customers"
          source="sender.id"
          link="show"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField
          label="recipient"
          reference="customers"
          source="recipient.id"
          link="show"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <TextField label="Origin" source="sender.address" />
        <TextField label="Destination" source="recipient.address" />
        <FunctionField
          label="Payment"
          render={(record) => <PaymentStatus consignmentId={record.id} />}
        />
        <TextField label="Status" source="status.value" />
        <TextField label="Tracking Code" source="trackingCode" />
        {props.permissions && props.permissions.consignments.edit && (
          <EditButton />
        )}
        {props.permissions && props.permissions.consignments.delete && (
          <DeleteButton undoable={false} />
        )}
      </Datagrid>
    </List>
  ) : null;
};

export default ConsignmentList;

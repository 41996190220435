import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Transaction = ({ transactions }) => {
  const classes = useStyles();

  return (
    <div>
      {transactions.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Transaction Id</TableCell>
                <TableCell align="right">Account Name</TableCell>
                <TableCell align="right">Account Number</TableCell>
                <TableCell align="right">Mode</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((trans) => (
                <TableRow key={trans.id}>
                  <TableCell component="th" scope="row">
                    {trans.id}
                  </TableCell>
                  <TableCell align="right">
                    {trans.data().accountName}
                  </TableCell>
                  <TableCell align="right">
                    {trans.data().accountNumber}
                  </TableCell>
                  <TableCell align="right">{trans.data().mode}</TableCell>
                  <TableCell align="right">{trans.data().status}</TableCell>
                  <TableCell align="right">{trans.data().amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};

Transaction.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default Transaction;

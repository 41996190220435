import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { SignUpPreview, SignUpPreviewText, AuthPreviewBtn } from "./AuthStyles";

const AuthPreview = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  toggleAuthSteps,
  displayName,
}) => {
  return (
    <SignUpPreview>
      <SignUpPreviewText>Continue with:</SignUpPreviewText>
      {displayName ||
        (firstName && lastName && (
          <SignUpPreviewText>
            <b>
              <i>
                {firstName} {lastName}
              </i>
            </b>
          </SignUpPreviewText>
        ))}
      <SignUpPreviewText>
        <b>
          <i>{email}</i>
        </b>
      </SignUpPreviewText>
      {phoneNumber && (
        <SignUpPreviewText>
          <b>
            <i>{phoneNumber}</i>
          </b>
        </SignUpPreviewText>
      )}
      {toggleAuthSteps && (
        <Fragment>
          <span>or </span>
          <AuthPreviewBtn color="primary" onClick={toggleAuthSteps}>
            Change?
          </AuthPreviewBtn>
        </Fragment>
      )}
    </SignUpPreview>
  );
};

AuthPreview.propTypes = {
  displayName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  toggleAuthSteps: PropTypes.func.isRequired,
};

export default AuthPreview;

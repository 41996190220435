import React from "react";
import styled from "styled-components";

const MerchantOrders = () => {
  return (
    <Wrapper>
      <div className="status_row">
        <button>ALL</button>
        <button>ONGOING</button>
        <button>PAST</button>
      </div>

      <table id="orders">
        <tr>
          <th>Order #</th>
          <th>Shipping date</th>
          <th>Status</th>
          <th>Sender</th>
          <th>Recipient</th>
          <th>Origin</th>
          <th>Destination</th>
          <th>Payment</th>
        </tr>
        <tr>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
        </tr>
        <tr>
        <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
        </tr>
        <tr>
        <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
        </tr>
        <tr>
        <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
        </tr>
        <tr>
        <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
          <td>hello</td>
        </tr>
      </table>
    </Wrapper>
  );
};

export default MerchantOrders;

const Wrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10rem;
  background-color: #fafafa;
  .status_row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  #orders {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #orders td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #orders tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #orders tr:hover {
    background-color: #ddd;
  }

  #orders th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4caf50;
    color: white;
  }
`;

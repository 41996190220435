import React from "react";
import { TextInput } from "react-admin";

// Read only textInput for displaying input that the user should not be able to edit
// but should be able to view for verification
const ReadOnlyTextInput = (props) => (
  <TextInput
    InputProps={{
      readOnly: true,
    }}
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

export default ReadOnlyTextInput;

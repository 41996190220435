import styled from "styled-components";
import { CountryDropdown } from "react-country-region-selector";

export const CountrySelect = styled(CountryDropdown)`
  outline: none;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 0.8rem;
  min-width: 100%;
`;


import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { 
  CreateButton,
  useListContext 
} from 'react-admin';
import InboxIcon from '@material-ui/icons/Inbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    color: 'grey',
    marginBottom: '2rem'
  },
  icon: {
    color: 'grey',
    fontSize: '10rem',
    marginTop: '4rem'
  }
})

function setResource(resource) {
  // function to get resource from collection url string
  // by turning string into array they getting last element
  let resourceName = resource.split("/").pop();

  // Capitalize the first letter
  resourceName = resourceName[0].toUpperCase() + resourceName.substring(1);
  return resourceName;
}

const EmptyListPage = (props) => {
  const { basePath, resource } = useListContext();
  const classes = useStyles();

  const ResourceIcon = props.icon || InboxIcon;

  return (
    <Box textAlign="center" m={1}>
      <Grid container spacing={3}>
        <Grid item xs={12}>          
          <ResourceIcon className={classes.icon} />
          <Typography variant="h4" paragraph className={classes.root}>
            No {setResource(resource)} yet.
          </Typography>
          <Typography variant="body1" className={classes.root}>
            Do you want to add one?
          </Typography>
          <CreateButton variant="contained" basePath={basePath} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmptyListPage;

import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import { Card, CardContent } from "@material-ui/core";
import DollarIcon from "@material-ui/icons/AttachMoney";
import { db } from "../../config/firebaseConfig";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "../StyledLink";

const styles = {
  card: {
    borderLeft: "solid 4px #e55425",
    backgroundColor: "#000039",
    color: "#fff",
  },
  CardContent: {
    fontSize: "1.5rem",
    color: "#fff",
  },
  icon: {
    color: "#00acc1",
  },
  h3: {
    fontWeight: "bold",
    margin: "0",
    fontSize: "1.8rem",
    color: "#fff",
  },
};

const RevenueStatus = () => {
  const profile = useContext(ProfileContext);
  const [state, setState] = useState({
    totalRevenue: 0.0,
  });

  useEffect(() => {
    const getPayments = async () => {
      console.log("#####inside getPayments()")
      try {
        // fetch the value of the company's summary (main) account balance
        // it is assumed the backend is responsible for continually updating the
        // wallet totals and thus all we need to do is to just read and display it
        const companyId = profile.company.id;
        const unsubscribe = db.collection(`companies/${companyId}/accounts`)
        .where("type", "==", "summary")
        .onSnapshot((snapshot) => {
          // extract the balance from the fetched account (we assume there's only 1)
          snapshot.forEach((doc) => {
            const acct = doc.data();
            setState((state) => ({ ...state, totalRevenue: acct.balance }));
          });
        });

        return unsubscribe;
      } catch (error) {
        console.log("##### error fetching: ", error)
      }
    };

    getPayments();
  }, [profile.company.id]);

  return (
    <Grid item xs={12} sm={4}>
      <Link to="/payments">
        <Card style={styles.card}>
          <CardContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <DollarIcon style={styles.icon} />
              <Typography variant="h3" gutterBottom style={styles.h3}>
                Ksh {state.totalRevenue}
              </Typography>
            </div>
            <Typography variant="h4" gutterBottom style={styles.CardContent}>
              Revenue
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default RevenueStatus;

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { sendSignInLinkToEmail } from "../../../config/firebaseConfig";
import { auth, googleProvider, db } from "../../../config/firebaseConfig";
import { useStyles } from "../AuthStyles";
import IndeterminateLoading from "../../../components/IndeterminateLoading";
import { AuthLink } from "../AuthStyles";
import PrimaryView from "./components/PrimaryView";
import SecondaryView from "./components/SecondaryView";

const SignIn = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [showDialog, setShowDialog] = React.useState(false);
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSubsequentSteps, setShowSubsequentSteps] = useState(false);
  const [
    isSigningInWithEmailAndPswd,
    setIsSigningInWithEmailAndPswd,
  ] = useState(false);
  const [
    isSigningInWithEmailAndLink,
    setIsSigningInWithEmailAndLink,
  ] = useState(false);

  // the isNewlyVerifiedAdmin prop is passed by the router from the params i.e ../signin/true
  // it is used to determine whether the current user is a newly verified admin
  // upon successfully verifying an admin, the backend will redirect to signin/true
  // to trigger display of both login page and verification success message.
  // For usuall login, the message will not be displayed
  const isNewlyVerifiedAdmin = props.isNewlyVerifiedAdmin || false;

  const isSignInWithEmailLink = auth.isSignInWithEmailLink(
    window.location.href
  );

  // automatically run passwordless sign in flow if we're coming from the email link
  useEffect(() => {
    // get email from pswd reset email to pre-populate the email input
    const qString = window.location.search;
    if (qString) {
      const urlParams = new URLSearchParams(qString);
      const pswdResetEmail = urlParams.get("email");
      setEmail(pswdResetEmail);
    }

    // sign in with email link if set
    if (isSignInWithEmailLink) {
      signInWithEmailLink();
    } else {
      console.log(
        "----------------- NOT isSignInWithEmailLink -------------- "
      );
    }
  }, []);

  const checkIsNewUser = async (email) => {
    let isNewUser = true;
    try {
      const admins = await db.collectionGroup("admins").get();

      Boolean(
        admins.docs.find((admin) => admin.data().emailAddress === email)
      ) && (isNewUser = false);
      return isNewUser;
    } catch (err) {
      console.error("***** err: fetching admin *****", err);
    }

    return isNewUser;
  };

  const handleSignInWithGoogle = async (e) => {
    e.preventDefault();
    try {
      const authResult = await auth.signInWithPopup(googleProvider);
      const user = authResult.user;
      const isNewUser = await checkIsNewUser(user.email);

      if (isNewUser) {
        // add completeSignUp and googleAuthUserPreview to be used in sign-up
        localStorage.setItem("completeSignUp", JSON.stringify(true));
        localStorage.setItem("googleAuthUserPreview", JSON.stringify(user));
        // redirect to '/signup' if it's a new user
        navigate("signup");
      } else {
        // redirect to the dashboard
        navigate("/");
      }
    } catch (err) {
      console.error(
        "*** an error occurred while signing in with google ****",
        err
      );
      setError(err.message);
    }
  };

  /**
   * completes the sign in on the login page by extracting auth data from the
   * sign in link; or resends the email if the current link is invalid
   * @param {React.ChangeEvent} event the button click submitting email data
   * @param {string} emailAddr the user's sign in address
   */
  const signInWithEmailLink = (event, emailAddr) => {
    event?.preventDefault();
    if (showSubsequentSteps) {
      // additional state parameters can also be passed via URL.
      // this can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // get the email if available, should be available if the user completes
      // the flow on the same device where they started it.
      console.log("emailAddr: ", emailAddr);
      var email = emailAddr || window.localStorage.getItem("emailForSignIn");
      console.log("email: ", email);
      if (!email) {
        // user opened the link on a different device. to prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // the client SDK will parse the one-time code from the link for us
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(async function (result) {
          // set setIsSigningInWithEmailAndLink to false as we're no longer signin in
          setIsSigningInWithEmailAndLink(false);

          // clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // access the new user via result.user
          console.log("email link auth user: ", result.user);

          const displayName = window.localStorage.getItem("adminDisplayName");
          // update auth.user with bio/metadata
          await result.user
            .updateProfile({
              displayName: displayName,
            })
            .then(() => {
              console.log("firebase user set with display name: ", displayName);
            })
            .catch((error) => {
              console.log("error setting firebase user display name:", error);
            });

          // additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          // window.location.assign("/"); // @todo is this line necessary? it used to be in the email + pwd method
        })
        .catch(function (error) {
          // clear email from storage so we don't use it on subsequent calls
          window.localStorage.removeItem("emailForSignIn");
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log(
            "error occurred when signing in with email link: ",
            error
          );
          // invalid/expired code: resend the email link
          if (error.code === "auth/argument-error") {
            sendSignInLinkToEmail(email)
              .then((result) => {
                console.log("sign in with email sent with result: ", result);
                // display loading dialog
                setShowDialog(true);
              })
              .catch((error) => {
                console.log(
                  " -------------- error with sending sign in email link: ",
                  error
                );
                setError(error.message);
                // hide loading dialog
                setShowDialog(false);
              });
          } else {
            setError(error.message);
            // hide loading dialog
            setShowDialog(false);
          }
        });
    } else {
      toggleAuthSteps(event);
      setIsSigningInWithEmailAndLink(true);
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  const handleEmailAndPasswordSignin = async (e) => {
    e.preventDefault();
    if (showSubsequentSteps) {
      if (!email && !password) {
        setFormErrors({
          email: "* This field is required",
          password: "* This field is required",
        });
      } else if (!email) {
        setFormErrors({
          email: "* This field is required",
        });
      } else if (!password) {
        setFormErrors({
          password: "* This field is required",
        });
      } else {
        try {
          const userCred = await auth.signInWithEmailAndPassword(
            email,
            password
          );
          // set setIsSigningInWithEmailAndPswd to false as we're no longer signing in
          setIsSigningInWithEmailAndPswd(false);
          console.log("***** success: here's the user *****", userCred.user);

          /**
           * when you sign out, RA redirects you to "/login" where we show the sign-in component.
           * when that happens and you successfully sign-in with email + pswd, we'll redirect you to "/".
           * However, when you launch the app for the first time, you'll be in "/" but you'll see the sign-in component.
           * In that case, we don't need to do the redirection because you're already in "/"
           *
           * see <TwAdmin />, to see how the UI components are evaluated, depending on whether a user is signed-in or not
           */
          if (props.location && props.location.pathname !== "/") {
            navigate("/");
          }
        } catch (err) {
          console.log(
            "***** an error occurred while signing in with email + pswd *******",
            err
          );

          const { code, message } = err;

          switch (code) {
            case "auth/wrong-password":
              setError(
                "The password is invalid, please enter the correct password. Alternatively, use the 'forgot password' option below to reset your password"
              );
              break;
            case "auth/user-not-found":
              // redirect to '/signup'
              localStorage.setItem(
                "completeSignInWithEmail",
                JSON.stringify(true)
              );
              navigate("signup");
            default:
              setError(message);
          }
        }
      }
    } else {
      toggleAuthSteps(e);
      setIsSigningInWithEmailAndPswd(true);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const toggleAuthSteps = (e) => {
    e.preventDefault();
    setShowSubsequentSteps(!showSubsequentSteps);
  };

  const EmailAndPswdBtn = () => (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      className={classes.submit}
      onClick={handleEmailAndPasswordSignin}
    >
      sign in with email and password
    </Button>
  );

  const EmailAndLinkBtn = () => (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      className={classes.submit}
      onClick={(event) => {
        signInWithEmailLink(event, email);
      }}
    >
      sign in with email ( without password)
    </Button>
  );

  const handleBack = (e) => {
    e.preventDefault();
    setIsSigningInWithEmailAndPswd(false);
    setIsSigningInWithEmailAndLink(false);
    setShowSubsequentSteps(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Twift Admin
        </Typography>
        <CssBaseline />
        <IndeterminateLoading
          showDialog={showDialog}
          message="Waiting for email verification. Please check the message we sent to your email address"
        />
        {isNewlyVerifiedAdmin && (
          <Paper className={classes.paper}>
            <Typography align="center">
              Your email was verified successfully!
              <br />
              Please login to access your company's dashboard
            </Typography>
          </Paper>
        )}
        {error !== null && (
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        )}
        <form className={classes.form} noValidate>
          {showSubsequentSteps ? (
            <SecondaryView
              email={email}
              password={password}
              formErrors={formErrors}
              onChangeHandler={onChangeHandler}
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              isSigningInWithEmailAndLink={isSigningInWithEmailAndLink}
              isSigningInWithEmailAndPswd={isSigningInWithEmailAndPswd}
              EmailAndLinkBtn={EmailAndLinkBtn}
              EmailAndPswdBtn={EmailAndPswdBtn}
              handleBack={handleBack}
            />
          ) : (
            <PrimaryView
              handleEmailAndPasswordSignin={handleEmailAndPasswordSignin}
              handleSignInWithGoogle={handleSignInWithGoogle}
              signInWithEmailLink={signInWithEmailLink}
              email={email}
              EmailAndPswdBtn={EmailAndPswdBtn}
              EmailAndLinkBtn={EmailAndLinkBtn}
            />
          )}
          <Grid
            className={classes.helperText}
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid item style={{ display: "flex", flexDirection: "column" }}>
              <AuthLink to="signup">
                Don't have an account?{" "}
                <span className={classes.link}>Sign Up</span>
              </AuthLink>
              <AuthLink to="/password-reset">
                <span className={classes.link}>Forgot password?</span>
              </AuthLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignIn;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../../AuthStyles";

const PrimaryView = ({
  handleSignInWithGoogle,
  EmailAndPswdBtn,
  EmailAndLinkBtn,
}) => {
  const authStyles = useStyles();

  return (
    <Fragment>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className={authStyles.googleBtn}
        onClick={handleSignInWithGoogle}
      >
        Sign in with Google
      </Button>
      <EmailAndPswdBtn />
      <EmailAndLinkBtn />
    </Fragment>
  );
};

PrimaryView.propTypes = {
  handleEmailAndPasswordSignin: PropTypes.func.isRequired,
  handleSignInWithGoogle: PropTypes.func.isRequired,
  signInWithEmailLink: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  EmailAndLinkBtn: PropTypes.func.isRequired,
  EmailAndPswdBtn: PropTypes.func.isRequired,
};

export default PrimaryView;

import React, { useState, useContext, useEffect } from "react";
import { ProfileContext } from "../providers/ProfileProvider";
import {
  TextInput,
  Button,
  SaveButton,
  AutocompleteInput,
  useCreate,
  useNotify,
  FormWithRedirect,
} from "react-admin";
import { useForm } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import { HiddenTextInput } from "./hiddenTextInput";
import {
  validateName,
  validatePhone,
  validateEmail,
  validateText,
} from "../utils/validators";
import PhoneNumberField from "../layout/TwPhoneNumberInput";
import CountryDropdownField from "../layout/TwCountryDropdownField";
import { db } from "../config/firebaseConfig";

const CustomerInput = ({ customerType, showWarningWhenUnsavedChanges }) => {
  /*
        This component allows one to search for customers using their phone numbers
        then sets the customer's id, and name, when the phone number is found.
        It also allows one to create a new customer if the searched customer does not exist
    */
  const profile = useContext(ProfileContext);
  const form = useForm();
  const [showDialog, setShowDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [create, { loading }] = useCreate("customers");
  const notify = useNotify();

  // build strings source names to be used in the components
  const firstName = `${customerType}.firstName`;
  const lastName = `${customerType}.lastName`;
  const phoneNumber = `${customerType}.phoneNumber`;
  const address = `${customerType}.address`;
  const city = `${customerType}.city`;
  const nearestLandmark = `${customerType}.nearestLandmark`;
  const id = `${customerType}.id`;

  const handleAutoCompleteChange = (val) => {
    if (val === "create-new") {
      setShowDialog(true);
    } else {
      const customer = customers.find((customer) => customer.id === val);
      if (customer) {
        form.change(phoneNumber, customer.phoneNumber);
        form.change(firstName, customer.firstName);
        form.change(lastName, customer.lastName);
        form.change(address, customer.address);
        form.change(city, customer.city);
        form.change(nearestLandmark, customer.nearestLandmark);
        form.change(id, customer.id);
      }
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false);
          notify("A new customer was created successfully");
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  useEffect(() => {
    const fetchCustomers = async (companyId) => {
      let custObjectsList = [];
      const custList = (
        await db
          .collection("customers")
          .where("companyId", "==", companyId)
          .get()
      ).docs;

      custList.forEach((cust) => {
        const custData = {
          id: cust.id,
          ...cust.data(),
        };
        // filter out deleted customers from the suggestion list
        return !custData.deleted || custData.deleted != true ?
        custObjectsList = [...custObjectsList, custData] : null
      });

      setCustomers(custObjectsList);
    };

    fetchCustomers(profile.company.id);
  }, [profile.company.id, showDialog]);

  useEffect(() => {
    showWarningWhenUnsavedChanges(false);
  }, [showWarningWhenUnsavedChanges]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <AutocompleteInput
            source={id}
            label="Phone Number"
            choices={customers}
            onChange={handleAutoCompleteChange}
            allowEmpty={true}
            emptyText="Create New"
            emptyValue="create-new"
            optionText="phoneNumber"
            optionValue="id"
            validate={validatePhone}
            options={{ autoComplete: "none" }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteInput
            source={id}
            label="First Name"
            choices={customers}
            onChange={handleAutoCompleteChange}
            allowEmpty={true}
            emptyText="Create New"
            emptyValue="create-new"
            optionText="firstName"
            optionValue="id"
            options={{ autoComplete: "none" }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteInput
            source={id}
            label="Last Name"
            choices={customers}
            onChange={handleAutoCompleteChange}
            allowEmpty={true}
            emptyText="Create New"
            emptyValue="create-new"
            optionText="lastName"
            optionValue="id"
            options={{ autoComplete: "none" }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source={address}
            label="Address"
            validate={validateName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            source={city}
            label="City"
            validate={validateName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            source={nearestLandmark}
            label="Nearest Landmark"
            validate={validateText}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <HiddenTextInput source={id} />
        </Grid>
      </Grid>

      {/* The create new customer Dialog */}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create New Customer"
      >
        <DialogTitle>Create New Customer</DialogTitle>

        <FormWithRedirect
          resource="customers"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="firstName" validate={validateName} />
                <br />
                <TextInput source="lastName" validate={validateName} />
                <br />
                <PhoneNumberField
                  placeholder="phone"
                  name="phoneNumber"
                  data={customers}
                />
                <br />
                <TextInput source="emailAddress" validate={validateEmail} />
                <br />
                <TextInput source="address" validate={validateName} />
                <br />
                <Grid item xs={12} sm={4} style={{ display: "flex" }}>
                  <CountryDropdownField name="country" />
                </Grid>
                <br />
                <TextInput source="city" validate={validateName} />
                <br />
                <TextInput source="nearestLandmark" validate={validateText} />
                <br />
                <TextInput source="bio" validate={validateText} />
                <HiddenTextInput
                  source="companyId"
                  initialValue={profile.company.id}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default CustomerInput;

/* eslint-disable */
import * as React from "react";
import { Layout } from "react-admin";
import TwAppBar from "./TwAppBar";
import TwMenu from "./TwMenu";

const TwLayout = (props) => (
  <Layout {...props} appBar={TwAppBar} menu={TwMenu} />
);

export default TwLayout;

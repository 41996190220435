// adapted from https://marmelab.com/react-admin/List.html#exporter
import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { DateTime } from "luxon";

/**
 * A function that converts and downloads resource table data as a csv file
 * @param {Array} resourceArr - data from the dataProvider ( called by RA)
 * @param {String} companyId - company id
 * @param {String} resourceType - type of resource being exported, e.g, customers
 *
 */
const exporter = ({
  resourceArr,
  companyId,
  resourceType,
}) => {
  const resourceToBeExported = resourceArr.filter(
    (resource) => resource.companyId === companyId
  );

  // use luxon to format date
  let downloadDate = DateTime.fromJSDate(new Date())
    .setZone("Africa/Nairobi")
    .toLocaleString(DateTime.DATE_SHORT);

  jsonExport(resourceToBeExported, (err, csv) => {
    if (err) {
      console.error(
        "**** an error occurred while converting data to csv ****",
        err
      );
    }

    // download as csv with the format 'resourceType_date', e.g, customers_15_03_2021.csv
    downloadCSV(csv, `${resourceType}_${downloadDate}`);
  });
};

export default exporter;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
  root: {
    width: "10rem",
    height: "10rem",
    margin: "3rem, auto",
  },
  text: {
    textAlign: "center",
    marginBottom: "1rem",
  },
});

const IndeterminateCircularProgress = (props) => {
  /*
  A component to show a circle loading progress
  that displays until stopped
  */
  const classes = useStyles();

  const handleCloseClick = () => {
    props.showDialog = false
  };

  return (
    <Dialog
      fullWidth
      open={props.showDialog}
      onClose={handleCloseClick}
      aria-label="Loading"
    >
      <Box className={classes.root}>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress variant="indeterminate" size="4rem" {...props} />
        </Box>
      </Box>
      <Typography
        variant="caption"
        component="div"
        color="textSecondary"
        className={classes.text}
      >
      {/* the dialog text to display */}
      {props.message}
      </Typography>
    </Dialog>
  );
};

export default IndeterminateCircularProgress;

import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  TextInput,
  ImageInput,
  ImageField,
  EditButton,
  DeleteButton,
} from "react-admin";

// Create a custom display of items in the table
// This also enables editing and creations to be consistent
// Without this, when edititng, the form may ignore fields without values
export const CompanyList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="average_note" />
      <ReferenceField
        label="Secrets"
        source="secrets"
        reference="secrets"
        link="show"
      >
        <TextField source="company" />
      </ReferenceField>
      <TextField source="brand_color" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export const CompanyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="logo" />
      <TextField source="name" />
      <TextField source="average_note" />
      <TextField source="tagline" />
      <TextField source="brand_color" />
      <ReferenceManyField label="Hubs" reference="hubs" target="company">
        <Datagrid>
          <TextField source="id" />
          <TextField source="manager" />
          <TextField source="name" />
          <TextField source="address" />
          <TextField source="name" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const validateCompanyCreation = (values) => {
  const errors = {};
  if (!values.id) {
    errors.id = ["The id is required"];
  }
  if (!values.name) {
    errors.name = ["The name is required"];
  } else if (Number.isInteger(values.name)) {
    errors.name = ["Name cannot be a number"];
  }
  return errors;
};

const number = (message = "Must be a number") => (value) =>
  value && isNaN(Number(value)) ? message : undefined;
const minValue = (min, message = "Too small") => (value) =>
  value && value < min ? message : undefined;

const validateNumber = [number(), minValue(0)];

const CompanyForm = () => (
  <SimpleForm validate={validateCompanyCreation} warnWhenUnsavedChanges>
    <TextInput source="id" />
    <ImageInput source="logo" label="Related pictures" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <TextInput source="name" />
    <NumberInput source="average_note" validate={validateNumber} />
    <TextInput multiline source="tagline" />
    <TextInput source="brand_color" />
  </SimpleForm>
)

export const CompanyCreate = (props) => (
  <Create {...props}>
    <CompanyForm />
  </Create>
);

export const CompanyEdit = (props) => (
  <Edit {...props}>
    <CompanyForm />
  </Edit>
);

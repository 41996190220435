import { db, functions, auth } from "../../config/firebaseConfig";
import formatPermissions from "../../components/formatPermissions";

class AuthAdminProfile {
  // uses the auth user object to retrieve other admin data
  // then creates a profile object from them
  constructor(user = null) {
    this.user = user;
    this.adminProfile = null;
  }

  async main() {
    await this.checkUser(this.user);
    await this.setAdmin();
    await this.setCompany();
    await this.setTeam();
    await this.setAdminProfile();
  }

  set profile(adminProfile) {
    this.adminProfile = adminProfile;
  }

  get profile() {
    return this.adminProfile;
  }

  async checkUser(user) {
    // confirms that the user object is a valid object from firebase
    // else stops auth process
    console.log("checking user");
    await function () {
      if (!user.uid || !user.email || user.isAnonymous || !user.refToken) {
        alert("Invalid user"); // TODO: show a dialog
        throw new Error("Invalid user!");
      }
    };

    // force refresh tokens to retrieve the latest custom claims. this makes sure
    // that subsequent steps (e.g. fetching/setting an admin's team) to have the right
    // permissions, otherwise we end up with permissions errors that produce auth loops
    await user.getIdToken(true);
    this.firebaseUser = user;
    console.log("user checked. UID:", user.uid);
  }

  async setAdmin() {
    // sets admin by fetching Twende admin using firebase user's credentials.
    // Uses email to search across all admin docs and retrieve one with a similar one
    // then creates an admin object
    if (!this.firebaseUser) {
      return false;
    }

    console.log(`time.admin.start - ${Date.now()}`);
    console.log("setting admin");
    var self = this;
    let adminDoc = null;
    let adminsRef = await db
      .collectionGroup("admins")
      .where("emailAddress", "==", this.firebaseUser.email);
    let admin = null;
    await adminsRef
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size > 1) {
          // fetch all the companies the admin is registered with
          let adminCompanies = [];
          querySnapshot.forEach(async (doc) => {
            if (doc.exists) {
              console.log("setting multiple companies..");
              let companyName;
              const companyId = doc.data().companyId;
              console.log("compani Id", companyId);
              db.collection("companies")
                .doc(companyId)
                .get()
                .then((doc) => {
                  companyName = doc.data().name;
                  console.log("Company Name", companyName);
                });
              let company = {
                companyId,
                companyName,
              };
              adminCompanies.push(company);
            }
          });
          await alert(`Your Email is registered with more than one company as follows:
                       ${JSON.stringify(adminCompanies)}.
                       This is not allowed!
                       Please contact your admins to have this solved`);
          await auth.signOut(); // signout user to clear cache
          window.location.assign("/"); //redirect to login
          throw new Error("Multiple Emails for one user");
        }
        //
        querySnapshot.forEach(async function (doc) {
          if (doc.exists) {
            adminDoc = doc; // make doc available out of current scope
            admin = {
              id: doc.id,
              displayName: self.firebaseUser.displayName,
              photoUrl: self.firebaseUser.photoURL, // Gmail photoURL
              firstName: doc.data().firstName,
              lastName: doc.data().lastName,
              emailAddress: doc.data().emailAddress,
              avatar: doc.data().avatar, // custom user avatar
              team: doc.data().team,
              companyId: doc.data().companyId,
            };

            // (post-firebase sign-in)
            // update the admin doc to trigger custom claims update. this will minimize
            // the chance that the current admin's doc saved under custom auth claim will
            // have differed from its actual doc ID (typically experienced with app instances that
            // share the same firebase auth configs e.g dev and dev-local)

            console.log(`time.claims.before - ${Date.now()}`);
            console.log(
              `about to set custom claims updating for: ${doc.ref.path}`
            );
            let adminData = doc.data();
            adminData.docId = doc.id;
            let updateClaims = functions.httpsCallable(
              "setCustomClaimsCallable"
            );
            await updateClaims(adminData)
              .then(function (result) {
                // Read result of the custom claim setting
                console.log(`time.claims.after - ${Date.now()}`);
                console.log("custom claims update result: ", result);
              })
              .catch(function (error) {
                console.log(
                  `there was an error setting custom claims: ${error.code} - ${error.message}. details: ${error.details}`
                );
              });
          } else {
            // admin is not registered in any company
            // (admin does not exist, the subsequent steps will fail and trigger a signup), via the ProfileProvider
            console.log("User is not registered as admin in any company");
          }
        });
      })
      .catch(
        await function (error) {
          console.log(
            `Error fetching admin doc of email address ${this.firebaseUser.email}: `,
            error
          );
        }
      );

    this.admin = admin;
    this.adminDoc = adminDoc;
    console.log(`time.admin.after - ${Date.now()}`);
    console.log("admin set: ", admin?.id);
  }

  async setCompany() {
    // sets parent company of admin
    // gets companyID by splitting path into array using '/' then gets second array element
    // i.e 'companies/twende/admins/timon'-> [ 'companies', 'twende', 'admins', 'timon' ]
    // if no company is found, direct admin to create company
    if (!this.admin) {
      return false;
    }
    console.log("setting company...");
    var companyDoc = null;
    const companyId = this.admin.companyId;

    if (companyId) {
      var companyRef = await db.collection("companies").doc(companyId);
      await companyRef
        .get()
        .then(function (doc) {
          if (doc.exists) {
            companyDoc = doc;
          } else {
            alert(`You are registered with an invalid company!
                               Please contact your admins to verify your details`);
            throw new Error("admin registered to invalid company");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      alert(`You are not registered!
                   Please proceed and register your company or
                   ask your admin to send you an invite`);
      throw new Error("admin not registered");
    }

    const company = {
      id: companyDoc.id,
      name: companyDoc.data().name,
      tagline: companyDoc.data().tagline,
      logo: companyDoc.data().logo,
    };
    this.company = company;
    console.log("company set", company);
  }

  async setTeam() {
    // sets admin's team
    // the team holds the admin's permissions
    // if the admin does not belong in any team, s/he gets no permissions
    // meaning s/he can only view the dashboard
    if (!this.company) {
      return false;
    }
    console.log(`time.team.start - ${Date.now()}`);
    console.log("fetching team for admin with id ", this.admin.id);
    var self = this;
    var team = null;
    var teamDoc = null;
    var teamRef = db.doc(
      `companies/${this.company.id}/teams/${this.admin.team.id}`
    );
    await teamRef
      .get()
      .then(async function (doc) {
        if (doc.exists) {
          teamDoc = doc;

          await self.formatPermissions(teamDoc.data());
          team = {
            id: teamDoc.id,
            name: teamDoc.data().name,
            permissions: self.permissions,
          };
        } else {
          console.log("User lacks any permission");
          team = {
            permissions: {},
          };
        }
      })
      .catch(function (error) {
        console.log("Error fetching team document:", error);
      });

    console.log(`time.team.end - ${Date.now()}`);
    this.team = team;
    console.log("team set", team);
  }

  async formatPermissions(teamData) {
    /*  formats and stores permissions in one permissio object
            assuming teamData = {name: 'superadmins',
                                permissions: {admins: ['read',],
                                              payments: ['read', 'write'],
                                              consignments: ['read', 'write', 'delete']
                                              ....
                                              }
                                }

            format to permissions = {
                                payments: {
                                            enabled: true,
                                            list: true,
                                            show: true,
                                            create: true,
                                            edit: true,
                                            delete: true
                                        },
                                companies/$companyid/admins: {
                                            enabled: true,
                                            list: true,
                                            show: true,
                                            create: true,
                                            edit: true,
                                            delete: true
                                        }
                                ...
                                ...
                                ...
                                }
                            }
            1# Get permissions object
            2# Loop for each child of permissions and format them
            3# then store them in a way react-admin-auth-acl lib understands
            4# In step 3, use the resource name as key since ra-auth-acl expects the key to map to the resource name
        */
    console.log("setting permissions...");

    var rawPermissions = teamData.permissions;
    var formatedPermissions = formatPermissions(
      rawPermissions,
      this.company.id
    );
    this.permissions = formatedPermissions;
    console.log("permissions set");
  }

  async setAdminProfile() {
    // sets the admin's profile
    // which is an object containing
    // personal details, company details, team and permissions
    console.log("setting profile...");
    const adminProfile = {
      // firebase user details
      authUser: this.user,

      // profile details
      admin: this.admin,

      // company details
      company: this.company,

      // team and permissions
      team: this.team,
    };
    this.profile = adminProfile;
    console.log("...profile set", adminProfile);
  }
}

export default AuthAdminProfile;

class ResourceName {
  constructor(companyId) {
    this.companyId = companyId;
    this._resourceNameObj = null;

    let company = this.companyId;

    if (!company) {
      throw new Error("companyId is required");
    }

    const companies = "companies",
      payments = "payments",
      customers = "customers",
      permissions = "permissions",
      messages = "messages",
      trips = "trips",
      bookings = "bookings",
      fleet = "fleet",
      owners = "owners",
      dispatches = "dispatches",
      consignments = "consignments",
      address = "address",
      ratings = "ratings";

    const admins = `/companies/${company}/admins`,
      hubs = `/companies/${company}/hubs`,
      accounts = `/companies/${company}/accounts`,
      teams = `/companies/${company}/teams`,
      routes = `/companies/${company}/route`,
      agents = `/companies/${company}/agents`,
      waybills = `/companies/${company}/waybills`,
      status = `/companies/${company}/status`;

    const resourceNames = {
      companies,
      admins,
      payments,
      customers,
      permissions,
      messages,
      trips,
      bookings,
      fleet,
      owners,
      dispatches,
      consignments,
      hubs,
      accounts,
      teams,
      routes,
      agents,
      waybills,
      address,
      status,
      ratings,
    };
    this.resourceNameObj = resourceNames;
    // console.log('SetResourceName: ', this._resourceNameObj);
  }
  set resourceNameObj(resourceNameObj) {
    this._resourceNameObj = resourceNameObj;
  }

  get resourceNameObj() {
    // console.log('GET: ', this._resourceNameObj);
    return this._resourceNameObj;
  }
}

export default ResourceName;

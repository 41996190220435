import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import DispatchHelperText from "../HelperText";
import { Wrapper, Header, SubHeader } from "./styles";

// styles - HeaderTableCell
const HeaderTableCell = withStyles(() => ({
  // set style for table cells
  head: {
    backgroundColor: "#e55425",
    color: "#000000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const PickListHeader = ({ title, helperText }) => {
  return (
    <Wrapper>
      <Header>
        <p>{title}</p>
        <DispatchHelperText title={helperText} />
      </Header>
      <SubHeader>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeaderTableCell>Order No.</HeaderTableCell>
                <HeaderTableCell align="left">Origin</HeaderTableCell>
                <HeaderTableCell align="left">Destination</HeaderTableCell>
                <HeaderTableCell align="left">Status</HeaderTableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </SubHeader>
    </Wrapper>
  );
};

PickListHeader.propTypes = {
  title: PropTypes.node.isRequired,
  helperText: PropTypes.string.isRequired,
};

export default PickListHeader;

import React from "react";
import { Card, CardContent } from "@material-ui/core";
import CustomerIcon from "@material-ui/icons/PersonOutline";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "../StyledLink";

const styles = {
  card: {
    borderLeft: "solid 4px #00acc1",
    backgroundColor: "#e55425",
  },
  icon: {
    color: "#00acc1",
  },
  CardContent: {
    fontSize: "1.5rem",
    color: "#000",
  },
  h1: { fontWeight: "bold", margin: "0", fontSize: "1.8rem" },
};

const NumberofCustomers = ({customersNo}) => {

  return (
    <Grid item xs={12} sm={4}>
      <Link to="/customers">
        <Card style={styles.card}>
          <CardContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <CustomerIcon style={styles.icon} />
              <Typography variant="h3" gutterBottom style={styles.h1}>
                {customersNo}
              </Typography>
            </div>
            <break />
            <Typography variant="h4" gutterBottom style={styles.CardContent}>
              Customers
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default NumberofCustomers;

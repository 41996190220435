import React, { useContext, useState, useEffect } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
  FunctionField,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import SectionHeader from "../../components/SectionHeader";
import {
  validateName,
  validateEmail,
  validateText,
  validateIdNumber,
  validateRequired,
} from "../../utils/validators";
import EmptyListPage from "../../components/EmptyListPage";
import AgentIcon from "@material-ui/icons/DriveEta";
import SaveCompanyOnSaveToolbar from "../../components/saveCompanyOnSave";
import PhoneNumberField from "../../layout/TwPhoneNumberInput";
import WithRecord from "../../components/WithRecord";
import Resources from "../../routes/resource-names";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";
import getResourceLink from "../../routes/resource-names";

export const AgentList = (props) => {
  const profile = useContext(ProfileContext);
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const companyId = profile.company.id;

  const resource = new Resources(companyId).resourceNameObj;

  const agentsExporter = (agents) =>
    exporter({
      resourceArr: agents,
      companyId: companyId,
      resourceType: "agents",
    });

  return companyId ? (
    <List
      {...props}
      title="Agents"
      sort={{ field: "createdAt", order: "DESC" }}
      filter={{ companyId: companyId }}
      empty={<EmptyListPage icon={AgentIcon} />}
      exporter={
        formattedPermissions[resource.agents] &&
        formattedPermissions[resource.agents].list
          ? agentsExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="Name"
          render={(record) => `${record.firstName} ${record.lastName}`}
        />
        <TextField source="phoneNumber" />
        <TextField source="rating" />
        {formattedPermissions[resource.agents] &&
          formattedPermissions[resource.agents].edit && <EditButton />}
        {formattedPermissions[resource.agents] &&
          formattedPermissions[resource.agents].delete && (
            <DeleteButton undoable={false} />
          )}
      </Datagrid>
    </List>
  ) : null;
};

export const AgentShow = (props) => (
  <Show {...props} title="Agent">
    <SimpleShowLayout>
      <FunctionField
        label="Name"
        render={(record) => `${record.firstName} ${record.lastName}`}
      />
      <TextField source="phoneNumber" />
      <TextField source="emailAddress" />
      <TextField source="registrationNumber" label="Registration Number" />
      <TextField source="nationalIDNumber" />\
      <TextField source="rating" />
      <TextField source="bio" />
    </SimpleShowLayout>
  </Show>
);

export const AgentForm = ({ record }) => {
  const vehicleTypes = [
    { id: "Trolley", name: "Trolley" },
    { id: "Hand Cart", name: "Hand Cart" },
    { id: "Motorbike", name: "Motorbike" },
    { id: "Car", name: "Car" },
    { id: "Van", name: "Van" },
    { id: "Pick-up", name: "Pick-up" },
    { id: "Mini-Lorry", name: "Mini-Lorry" },
    { id: "Lorry", name: "Lorry" },
    { id: "Trailer", name: "Trailer" },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <SectionHeader text="Personal Details" />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextInput source="firstName" validate={validateName} fullWidth />
        </Grid>
        <Grid item sm={3} xs={12}>
          <TextInput source="lastName" validate={validateName} fullWidth />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInput
            source="nationalIDNumber"
            label="National ID No"
            validate={validateIdNumber}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneNumberField defaultValue={record && record.phoneNumber} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInput source="emailAddress" validate={validateEmail} fullWidth />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextInput multiline source="bio" validate={validateText} fullWidth />
        </Grid>
        <Grid item sm={12} xs={12}>
          <SectionHeader text="Vehicle Details" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectInput
            source="vehicleType"
            label="Vehicle Type"
            choices={vehicleTypes}
            validate={validateText}
            fullWidth
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <BooleanInput
            source="hasPrimaryVehicle"
            style={{ marginTop: "1.8rem" }}
          />
        </Grid>
        <Grid container item sm={12} xs={12}>
          <FormDataConsumer>
            {({ formData }) => {
              const registeredVehicles = [
                "Motorbike",
                "Car",
                "Van",
                "Pick-up",
                "Mini-Lorry",
                "Lorry",
                "Trailer",
              ];
              if (
                registeredVehicles.includes(formData.vehicleType) &&
                formData.hasPrimaryVehicle === true
              ) {
                return (
                  <Grid container spacing={2} item sm={12} xs={12}>
                    <Grid item sm={6} xs={12}>
                      <TextInput
                        source="registrationNumber"
                        label="Registration Number"
                        validate={validateRequired}
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextInput
                        source="drivingLicenseNo"
                        label="Driving License Number"
                        validate={validateRequired}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                );
              }
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
    </div>
  );
};

export const AgentCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The agent profile was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The agent profile was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Agent/Create"
    >
      <SimpleForm toolbar={<SaveCompanyOnSaveToolbar />} warnWhenUnsavedChanges>
        <AgentForm />
      </SimpleForm>
    </Create>
  );
};

export const AgentEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The agent profile was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The agent profile was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Agent/Edit"
    >
      <WithRecord>
        {({ record, ...props }) => (
          <SimpleForm record={record} {...props} warnWhenUnsavedChanges>
            <AgentForm />
          </SimpleForm>
        )}
      </WithRecord>
    </Edit>
  );
};

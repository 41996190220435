import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

import { TwToolTip } from "./styles";

const Tooltip = ({ title, icon }) => {
  return (
    <TwToolTip title={title}>
      <Button>{icon}</Button>
    </TwToolTip>
  );
};

Tooltip.defaultProps = {
  icon: <HelpOutlineOutlinedIcon />,
};

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default Tooltip;

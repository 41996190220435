import {
  required,
  minLength,
  maxLength,
  minValue,
  number,
  regex,
  email,
} from "react-admin";

export const validatePhone = [
  required(),
  minLength(10),
  regex(/(?:^\+)?\d+/, "Can contain only digits and +"),
];
export const validateName = [
  required(),
  minLength(2),
  maxLength(25),
  regex(/([a-zA-Z'\s]+)/, "Can contain only alphabets , space, and apostrophe"),
];
export const validatePlaceName = [
  required(),
  minLength(2),
  maxLength(25),
  regex(
    /([a-zA-Z0-9'-\s]+)/,
    "Can contain only alphabenumerics, space, ', and -"
  ),
];
export const validateEmail = email();
export const validateRequiredEmail = [
  required("This field is required"),
  email("Invalid email"),
];
export const validateMeasurement = [
  required(),
  number(),
  minValue(1),
  minLength(2),
];
export const validateRequired = required("Required");
export const validateAmount = [
  required(),
  minValue(1, "Amount value cannot be less than 1"),
  regex(
    /^\d+(?:(\.))?(\d{2})/,
    "Can contain only whole or two-decimal point numbers"
  ),
];
export const validateText = regex(
  /([\w'-\s.]+)/,
  "Can contain only alphabenumerics, space, -, ', and _"
);
export const validateIdNumber = [
  required(),
  number(),
  minLength(6),
  maxLength(8),
];

import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";

import { AgentForm } from "./agents";
import Resources from "../../routes/resource-names";

// AgentQuickCreate - allows one to quickly create agents.
// It's rendered as a Dialog, depending on the showDialog: bool value
const AgentQuickCreate = ({ defaultState, onDialogClosed, companyId }) => {
  // get the agents resource to be used with useCreate()

  // resourcesObject gives you = {companyId: value, _resourceNameObj: { resource1: value, resource2: value, ... }}
  const resourcesObject = new Resources(companyId);
  const resources = resourcesObject._resourceNameObj;
  const agentsResource = resources.agents;

  const [showDialog, setShowDialog] = useState(defaultState);
  const [create, { loading }] = useCreate(agentsResource);
  const notify = useNotify();

  const handleCloseClick = () => {
    setShowDialog(false);
    try {
      // if function was passed as prop call it
      onDialogClosed();
    } catch (error) {
      // if it wasn't, hence an exception, do nothing
    }
  };

  const handleSubmit = async (values) => {
    console.log("VALUES: ", values);
    create(
      { payload: { data: { ...values, companyId: companyId } } },
      {
        onSuccess: ({ data }) => {
          notify("Agent created successfully!", "success");
          handleCloseClick();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      {/* The create agent Dialog */}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create Agent"
      >
        <DialogTitle>Consignment Agent Info</DialogTitle>

        <FormWithRedirect
          resource="payments"
          save={handleSubmit}
          // initialValues={initialValues}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <AgentForm />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

AgentQuickCreate.propTypes = {
  defaultState: PropTypes.bool.isRequired,
  onDialogClosed: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default AgentQuickCreate;

import * as React from "react";
import {
  List,
  Show,
  Edit,
  Create,
  Datagrid,
  SimpleShowLayout,
  SimpleForm,
  TextInput,
  TextField,
  EditButton,
  DeleteButton,
  useRedirect,
  useNotify,
} from "react-admin";
import LinkSubcollection from "../../components/link-button";
// import MyTextField from './custom-field';
import MyUrlField from "../../components/url-field";

const HubForm = () => (
  <SimpleForm warnWhenUnsavedChanges>
    <TextInput source="address" />
    <TextInput source="name" />
    <TextInput source="manager" />
    <TextInput source="company" />
  </SimpleForm>
)

export const HubList = (props) => (
  <List {...props} title="Hubs">
    <Datagrid>
      <TextField source="name" />
      <TextField source="address" />
      <MyUrlField source="company" />
      <TextField source="manager" />
      <LinkSubcollection segment="easy-coach" label="Link" />
      <EditButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

export const HubShow = (props) => (
  <Show {...props} title="Hub">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="company" />
      <TextField source="manager" />
    </SimpleShowLayout>
  </Show>
);

export const HubCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The hub was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The hub was not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Hub/Create"
    >
      <HubForm />
    </Create>
  );
};

export const HubEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The hub was updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The hub was not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Hub/Edit"
    >
      <HubForm />
    </Edit>
  );
};

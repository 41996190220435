import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";

const PhoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      size="small"
      label="Phone Number"
      variant="outlined"
      inputProps={{
        autoComplete: "new-password",
      }}
    />
  );
};
export default forwardRef(PhoneInput);

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HelperText = styled.small`
  font-size: 0.7rem;
  font-weight: normal;
`;

const DispatchHelperText = ({ title }) => {
  return <HelperText>{title}</HelperText>;
};

DispatchHelperText.propTypes = {
  title: PropTypes.node.isRequired,
};

export default DispatchHelperText;

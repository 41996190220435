import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { PickList } from "primereact/picklist";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";
import PropTypes from "prop-types";

import PickListHeader from "../../../Dispatches/components/PickListHeader";

const SelectConsignments = ({
  initialSource,
  sendTargetToParent,
  sendSourceToParent,
  sourceHeader,
  initialTarget,
  targetHeader,
  sourceHelperText,
  targetHelperText,
}) => {
  const [state, setState] = useState({
    source: initialSource,
    target: initialTarget || [],
  });

  const { source, target } = state;

  const onChange = (e) => {
    setState({ source: e.source, target: e.target });
  };

  // styles - StripedTableRow
  const StripedTableRow = withStyles(() => ({
    // set stripped style for table rows
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#c4c4c4",
      },
    },
  }))(TableRow);

  /**
   * @returns - component to be shown as an item in source and/or target.
   * @param {Object} consignment
   */
  const getItemTemplate = (consignment) => (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <StripedTableRow>
            <TableCell component="th" scope="row">
              {consignment.trackingCode}
            </TableCell>
            <TableCell align="left">{consignment.origin?.name}</TableCell>
            <TableCell align="left">{consignment.destination?.name}</TableCell>
            <TableCell align="left">{consignment.status?.value}</TableCell>
          </StripedTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  // send the value of target/selected items to the component that calls <SelectConsignments />
  useEffect(() => {
    sendTargetToParent(target);
  }, [target, sendTargetToParent]);

  // send the value of target/selected items to the component that calls <SelectConsignments />
  useEffect(() => {
    sendSourceToParent && sendSourceToParent(source);
  }, [source, sendSourceToParent]);

  return (
    <div className="picklist-demo">
      <div className="card">
        <PickList
          source={source}
          target={target}
          itemTemplate={getItemTemplate}
          sourceHeader={
            <PickListHeader
              title={sourceHeader}
              helperText={sourceHelperText}
            />
          }
          targetHeader={
            <PickListHeader
              title={targetHeader}
              helperText={targetHelperText}
            />
          }
          sourceStyle={{ height: "auto", width: "auto" }}
          targetStyle={{ height: "auto", width: "auto" }}
          onChange={onChange}
        ></PickList>
      </div>
    </div>
  );
};

// default props
SelectConsignments.defaultProps = {
  sourceHeader: "Available",
  targetHeader: "Selected",
};

SelectConsignments.propTypes = {
  initialSource: PropTypes.array.isRequired,
  initialTarget: PropTypes.array.isRequired,
  sendTargetToParent: PropTypes.func.isRequired,
  sendSourceToParent: PropTypes.func,
  sourceHeader: PropTypes.string.isRequired,
  targetHeader: PropTypes.string.isRequired,
  sourceHelperText: PropTypes.string.isRequired,
  targetHelperText: PropTypes.string.isRequired,
};

export default SelectConsignments;

import React, { useContext } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  BooleanField,
  BooleanInput,
} from "react-admin";

export const PermissionList = (props) => {
  const profile = useContext(ProfileContext);
  const company = profile.company.id;

  return company ? (
    <List {...props} filter={{ company: company }}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="entity" />
        <BooleanField source="create" />
        <BooleanField source="read" />
        <BooleanField source="update" />
        <BooleanField source="delete" />
        <TextField source="team" />
        <EditButton />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  ) : null;
};

export const PermissionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="entity" />
      <BooleanField source="create" />
      <BooleanField source="read" />
      <BooleanField source="update" />
      <BooleanField source="delete" />
      <TextField source="team" />
    </SimpleShowLayout>
  </Show>
);

const PermissionForm = () => (
  <SimpleForm redirect="list" warnWhenUnsavedChanges>
    <TextInput source="id" />
    <TextInput source="entity" />
    <BooleanInput source="create" />
    <BooleanInput source="read" />
    <BooleanInput source="update" />
    <BooleanInput source="delete" />
    <TextField source="team" />
  </SimpleForm>
)

export const PermissionCreate = (props) => (
  <Create {...props}>
    <PermissionForm />
  </Create>
);

export const PermissionEdit = (props) => (
  <Edit {...props}>
    <PermissionForm />
  </Edit>
);

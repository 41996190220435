/* eslint-disable */
import React, { Component, createContext } from "react";
import { auth } from "../config/firebaseConfig";
import { createOrUpdateUser } from "../pages/auth/CreateUser";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (userAuth) => {
      const user = await createOrUpdateUser(userAuth);
      this.setState({ user });
    });
  }

  render() {
    const { user } = this.state;

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;

/* eslint-disable */
import React, { useContext } from "react";
import { ProfileContext } from "../../providers/ProfileProvider";
const ProfilePage = () => {
  const profile = useContext(ProfileContext);
  const user = profile.authUser;
  const { photoURL, displayName, email } = user;
  console.log(user);

  return (
    <div className="mx-auto w-11/12 md:w-2/4 py-1 px-4 md:px-0 object-right ">
      <div className="flex border  flex justify-end border-none flex-col items-right md:flex-row md:items-start  px-0 py-0">
        <div
          style={{
            background: `url(${
              photoURL ||
              "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
            })  no-repeat center center`,
            backgroundSize: "cover",
            height: "50px",
            width: "50px",
          }}
          className="border rounded-full  border-blue-300 object-right"
        ></div>
        <div className="md:pl-4">
          <h4 className="font-semibold"> {displayName}</h4>
          <h3 className="font-semibold">{email}</h3>
        </div>
        {/* <button
          className="object-right  py-3 bg-blue 800 hover:bg-red-400 mt-3 text-white text-sm text-right"
          onClick={() => {
            auth.signOut();
          }}
        >
          Sign out
        </button>  remove other signup button*/}
      </div>
    </div>
  );
};

export default ProfilePage;

import React from "react";
import { Card, CardContent } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingBasketOutlined";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "../StyledLink";

const styles = {
  card: {
    backgroundColor: "#00acc1",
    color: "#000",
  },
  h1: { fontWeight: "bold", margin: "0", fontSize: "1.8rem" },
  CardContent: {
    fontSize: "1.5rem",
    color: "#000",
  },
  icon: {
    color: "#e55425",
  },
};

const NumberofConsignments = ({consignmentsNo}) => {

  return (
    <Grid item xs={12} sm={4}>
      <Link to="/consignments">
        <Card style={styles.card}>
          <CardContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <ShoppingCartIcon style={styles.icon} />
              <Typography variant="h1" gutterBottom style={styles.h1}>
                {consignmentsNo}
              </Typography>
            </div>
            <Typography variant="h4" gutterBottom style={styles.CardContent}>
              Consignments
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default NumberofConsignments;

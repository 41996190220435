/* eslint-disable */
import React, { useContext } from "react";
import { firebaseConfig } from "../config/firebaseConfig";
import { theme } from "../config/themeConfig";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import { Router } from "@reach/router";
import SignIn from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import ProfileProvider, { ProfileContext } from "../providers/ProfileProvider";
import TwAdmin from "../pages/admin";
import PasswordReset from "../pages/auth/CustomForgotPassword";
import ValidateInvite from "../pages/auth/ValidateInvite";
import { ThemeProvider } from "@material-ui/core/styles";
import MerchantHome from "../pages/merchant/home/index";
import MerchantRequestOrder from "../pages/merchant/request_order/index";
import MerchantOrders from "../pages/merchant/orders/index";
import CompanySignUp from "../pages/auth/CompanySignUp";
import SignOut from "../pages/auth/SignOut";
import LogRocket from "logrocket";
// import 404 page for testing
import PageNotFound from "../pages/404";

//firebase data and authprovider
function App() {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);

  const user = useContext(ProfileContext);

  // Use firebasa data provider to fetch data

  console.log("from app.js", user);
  return (
    <ProfileProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <TwAdmin path="/" />
          <SignUp path="/signup" />
          <SignIn path="/signin" />
          <CompanySignUp path="/register-company" />
          <SignIn path="signin/:isNewlyVerifiedAdmin" />
          <PasswordReset path="password-reset" />
          <SignOut path="/signout" />
          <ValidateInvite path="/accept-invite" />
          <MerchantHome path="/merchant/home" />
          <MerchantRequestOrder path="/merchant/request/recipient" />
          <MerchantOrders path="/merchant/orders" />
          <PageNotFound default />
        </Router>
      </ThemeProvider>
    </ProfileProvider>
  );
}

export default App;

import * as React from "react";
import {
  List,
  Show,
  Datagrid,
  TextField,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  DeleteButton,
  CheckboxGroupInput,
  useRedirect,
  useNotify,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import EmptyListPage from "../../components/EmptyListPage";
import TeamIcon from "@material-ui/icons/AssignmentInd";
import Resources from "../../routes/resource-names";
import { ProfileContext } from "../../providers/ProfileProvider";
import exporter from "../../components/customDataExporter";
import useFetchCurrentUserPermissions from "../../components/useFetchCurrentUserPermissions";

export const TeamList = (props) => {
  const profile = React.useContext(ProfileContext);
  const { formattedPermissions } = useFetchCurrentUserPermissions();
  const companyId = profile.company.id;

  const teamsResource = new Resources(companyId).resourceNameObj.teams;

  const teamsExporter = (teams) =>
    exporter({
      resourceArr: teams,
      companyId: companyId,
      resourceType: "teams",
    });
  return (
    <List
      {...props}
      title="Teams"
      empty={<EmptyListPage icon={TeamIcon} />}
      exporter={
        formattedPermissions[teamsResource] &&
        formattedPermissions[teamsResource].list
          ? teamsExporter
          : false
      }
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        {formattedPermissions[teamsResource] &&
          formattedPermissions[teamsResource].edit && <EditButton />}
        {formattedPermissions[teamsResource] &&
          formattedPermissions[teamsResource].delete && (
            <DeleteButton undoable={false} />
          )}
      </Datagrid>
    </List>
  );
};

export const TeamShow = (props) => (
  <Show {...props} title="Team">
    <SimpleForm toolbar={null} warnWhenUnsavedChanges>
      <TextField source="name" />
      <PermissionInputDisabled />
    </SimpleForm>
  </Show>
);

export const TeamCreate = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The team was created successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The team details were not created!", "warning");
    console.log(error);
  };
  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Team/Create"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label="Team Name" source="name" style={{ width: "80vw" }} />
        <PermissionInput disabled={true} />
      </SimpleForm>
    </Create>
  );
};

export const TeamEdit = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = () => {
    notify("The team details were updated successfully");
    redirect("list", props.basePath);
  };

  const onFailure = (error) => {
    notify("The team details were not updated!", "warning");
    console.log(error);
  };
  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      onFailure={onFailure}
      title="Team/Edit"
    >
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput label=" Team Name" source="name" style={{ width: "80vw" }} />
        <PermissionInput />
      </SimpleForm>
    </Edit>
  );
};

const permissions = [
  { id: "read", name: "Read" },
  { id: "write", name: "Write" },
  { id: "delete", name: "Delete" },
];

const styles = {
  height: "23em",
  overflowY: "auto",
};

// set courier entities, will later come from the db
let courierEntities = [
  "Accounts",
  "Hubs",
  "Admins",
  "Teams",
  "Dispatches",
  "Consignments",
  "Fleet",
  "Agents",
  "Payments",
  "Customers",
  "Messages",
  "Routes",
];

const PermissionInput = () => (
  <div style={styles}>
    {courierEntities.map((entity) => (
      <Grid item xs={12} sm={12}>
        <CheckboxGroupInput
          source={`permissions.${entity.toLowerCase()}`}
          label={entity}
          choices={permissions}
        />
      </Grid>
    ))}
  </div>
);

const PermissionInputDisabled = () => (
  <div style={styles}>
    {courierEntities.map((entity) => (
      <Grid item xs={12} sm={12}>
        <CheckboxGroupInput
          source={`permissions.${entity.toLowerCase()}`}
          label={entity}
          choices={permissions}
          options={{ disabled: true }}
        />
      </Grid>
    ))}
  </div>
);

import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { PaymentList } from "./payments";

export const AccountList = (props) => (
  <List {...props} title="Account">
    <Datagrid expand={<PaymentList />}>
      <TextField source="balance" />
    </Datagrid>
  </List>
);

import { db } from "../../../config/firebaseConfig";

const createOrUpdateADispatch = async ({
  method,
  payload,
  successCallback,
  failureCallback,
  dispatchId,
}) => {
  const dispatchRef = db.collection("dispatches");
  let result;
  if (method === "create") {
    try {
      result = await dispatchRef.add(payload);
      if (successCallback) successCallback(result.id);
    } catch (err) {
      if (failureCallback) failureCallback(err);
    }
  } else if (method === "update") {
    try {
      result = await dispatchRef.doc(dispatchId).update(payload);
      if (successCallback) successCallback();
    } catch (err) {
      if (failureCallback) failureCallback(err);
    }
  }
};

export { createOrUpdateADispatch };

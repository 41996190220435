import React from "react";
import styled from "styled-components";

const MerchantHome = () => {
  return (
    <Wrapper>
      <p>March 1st, 2021</p>
      <h2>Good morning</h2>
      <p>Here is what has been happening this past week at xxx</p>
      <div className="card_row">
        <StatusCard>
          <p>5</p>
          <p>Ongoing deliveries</p>
        </StatusCard>
        <StatusCard>
          <p>5</p>
          <p>Ongoing deliveries</p>
        </StatusCard>
        <StatusCard>
          <p>5</p>
          <p>Ongoing deliveries</p>
        </StatusCard>
      </div>
    </Wrapper>
  );
};

export default MerchantHome;

const Wrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10rem;
  background-color: #fafafa;
  .card_row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const StatusCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  justify-content: flex-start;
  box-shadow: 0px 0px 3px #00000029;
  border: 2px solid #c17600;
  margin-right: 2rem;
  border-radius: 5px;
`;
